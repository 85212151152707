.adminNav {
    @media only screen and (max-width: $md-plus-devices) {
        display: flex;
        flex-direction: column;
    }
}

.sidenav {
    overflow: hidden;
    font-family: $text-font;
    background-color: $blue-grey;
    position: fixed;
    height: 100%;
    width: 140px;
    z-index: 3;
    top: 0;
    left: 0;
    @include clean-lists;
    box-shadow: 4px 5px 6px -5px #00000041;

    &__logo {
        text-align: center;
        margin: 0;
        height: 116px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 55px;
        }
    }

    a {
        text-decoration: none;

        &.active, &.active:hover {
            color: $yellow;
            text-decoration: underline;
            text-decoration: underline dashed $yellow;
            text-decoration-thickness: 2px;
            text-underline-offset: 4px;
            font-weight: 500;
            text-shadow: none;

            &.active:hover {
                color: $blue-grey;
            }
        }
    }

    &__item, &__item > a {
        text-align: center;
        display: block;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        color: $white;
        cursor: pointer;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);

        &:hover {
            background-color: $yellow;
            font-weight: 500;
            color: $blue-grey;

            a {
                color: $blue-grey;
                font-weight: 500;
                text-shadow: none;
            }
        }
    }

    &__item a, .sidenav__subnav__item {
        padding: 20px 0;
    }

    &__subnav {
        &:hover > .sidenav__subnav__list {
            display: block;
        }

        &__item {
            @extend .sidenav__item;
        }

        &__list {
            display: none;
            background-color: $yellow;
            height: 100%;
            width: 180px;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 140px;
            overflow-x: hidden;
            padding-top: 122px;

            a {
                width: 100%;
                display: block;
                font-size: 17px;
                font-weight: 500;
                text-align: center;
                padding: 14px;
                text-decoration: none;

                &:hover {
                    text-shadow: 0 0 0.2px;
                }

                &.active--nav {
                    text-shadow: 0 0 0.2px;
                    text-decoration: underline;
                }
            }
        }
    }

    @media only screen and (max-width: $md-plus-devices) {
        position: unset;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 150px;
        box-shadow: $box-shadow-card;
        
        &__logo {
            display: none;
        }

        &__item, &__subnav {
            width: 100%;
            padding: 0;

            a {
                padding: 18px 20px;
                width: 100%;

                @media only screen and (max-width: $sm-devices) {
                    padding: 14px 12px;
                }
            }

            &__list {
                width: 100%;
                left: 0;
                top: 151px;
                height: auto;
                text-align: center;
                padding-top: 0;
                box-shadow: $box-shadow-card;

                a {
                    width: auto;
                    display: inline-block;
                    padding: 24px 20px;
                }
            }
        }
    }

    @media only screen and (max-width: $md-devices) {
        padding: 0;

        &__item, &__subnav {
            &__list {
                top: 182px;

                @media only screen and (max-width: $sm-devices) {
                    top: 168px;
                }
            }
        }
    }
}

.sidenav .sidenav__item:hover, .sidenav__subnav:hover .sidenav__subnav__item {
    background-color: $yellow;
    color: $blue-grey;
    font-weight: 500;
    text-transform: uppercase;
    text-shadow: none;
}

.topnav {
    overflow: hidden;
    font-family: $text-font;
    background-color: $white;
    position: fixed;
    height: 100px;
    width: calc(100% - 140px);
    top: 0;
    left: 140px;
    padding: 20px 60px;
    padding: 20px 60px 20px 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 4px solid $yellow;

    @media only screen and (min-width: 2000px) {
        padding: 20px 88px 20px 70px;
    }

    &__start {
        display: flex;
        align-items: center;

        p {
            display: inline-block;
            font-size: 26px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-family: $text-font;
            font-weight: 500;
            cursor: pointer;
        }

        @media only screen and (max-width: $md-plus-devices) {
            p {
                margin: 5px 0;
                font-size: 24px;

                @media only screen and (max-width: $sm-devices) {
                    font-size: 20px;
                }
            }
        }
    }

    &__menu {
        text-align: right;

        p, a > p {
            display: inline-block;

            img {
                vertical-align: middle;
                margin-right: 4px;
            }

            &:last-child {
                font-weight: 500;
                text-transform: uppercase;
                margin-left: 20px;
                cursor: pointer;

                &:hover {
                    text-shadow: 0 0 0.2px;
                }

                img {
                    height: 30px;
                }
            }
        }

        &--logout {
            margin-bottom: 3px;
            height: 28px;
        }
    }

    @media only screen and (max-width: $md-plus-devices) {
        position: unset;
        width: 100%;
        height: auto;
        display: block;
        order: -1;
        overflow: unset;
        display: flex;
        justify-content: space-between;
        padding: 15px 35px;
        border-bottom: 0;
    }

    @media only screen and (max-width: $md-devices) {
        justify-content: unset;
        flex-direction: column;
        text-align: center;
        padding: 15px 15px;

        &__start {
            width: 100%;
            display: block;
        }

        &__menu {
            text-align: center;
            order: -1;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            p, a > p {
                &:last-child {
                    margin: 0;
                }
            }
        }

        p:nth-child(3) {
            font-size: 22px;
        }
    }
}
