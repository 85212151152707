.breadcrumbs {
    background-color: $grey;
    color: $blue-grey;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    @include clean-lists;
    text-align: left;
    width: 100%;
    font-size: 14px;
    margin-top: 20px;
    @include user-select;
    border-radius: 3px;

    @media only screen and (max-width: $md-plus-devices) {
        justify-content: center;
        background-color: transparent;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 12px 18px 12px 18px;
        position: relative;
        text-decoration: none;
        font-weight: 500;

        @media only screen and (max-width: $md-plus-devices) {
            display: none;
        }

        &.is-active {
            background-color: $blue-grey;
            min-width: 130px;
            color: $white;

            @media only screen and (max-width: $md-plus-devices) {
                display: flex;
                background-color: transparent;
                color: $blue-grey;
                padding: 0;
                font-size: 17px;
            }

            span {
                color: $yellow;

                @media only screen and (max-width: $md-plus-devices) {
                    display: inline-block;
                    font-size: 28px;
                    height: 44px;
                    width: 44px;
                    border-radius: 50%;
                    text-align: center;
                    padding: 4px 12px 0 12px;
                    background: $blue-grey;
                }
            }

            &:after,
            :before {
                background-color: $blue-grey;
                bottom: 0;
                clip-path: polygon(50% 50%, -50% -50%, 0 100%);
                content: "";
                left: 100%;
                position: absolute;
                top: 0;
                width: 42px;
                z-index: 1;

                @media only screen and (max-width: $md-plus-devices) {
                    display: none;
                }
            }
        }

        &.last-active {
            @extend .is-active;

            &:after,
            :before {
                clip-path: polygon(100% 0, 100% 100%, 45% 50%);
                left: unset;
                right: 100%;
            }
        }

        &:last-child {
            border-right: none;
        }

        span {
            font-weight: 500;
            font-size: 18px;
            margin-right: 10px;
        }
    }
}
