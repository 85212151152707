.fullPage__error__container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/icons/wheat_field_errorPage_.jpg");
    background-position: center;
    background-size: cover;
    position: relative;

    &--content {
        @include absolute-center;
        text-align: center;
        padding: 60px 60px;
        min-width: 600px;
        background-color: white;
        border-radius: 5px;
        box-shadow: $box-shadow-card;

        h1 {
            margin-top: 0;
        }

        @media only screen and (max-width: $md-devices) {
            min-width: 400px;
        }

        @media only screen and (max-width: $sm-devices) {
            min-width: unset;
            padding: 20px 30px;
        }
    }

    .dedication {
        margin: 0;
        position: absolute;
        bottom: 8px;
        right: 8px;
        font-size: 14px;
        font-weight: 500;
        color: rgb(17, 17, 17);
    }
}