.feedback {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    height: 30px;
    justify-content: flex-start;

    @media only screen and (max-width: $sm-devices) {
        display: inline-table;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    span:nth-child(2) {
        vertical-align: sub;
        margin-left: 5px;
        cursor: pointer;
        color: $form-error;
    }

    &--spacing {
        padding: 0 20px;
    }

    &--outline {
        width: fit-content;
        display: inline-flex;
        align-items: center;

        p {
            margin: 0 0 2px 0;
            padding: 0;
            font-size: 14px; 
            font-weight: 500;
        }
    }
 
    &--success {
        @extend .feedback--outline;
        border-bottom: 2px solid $success-color;
    }

    &--warning {
        @extend .feedback--outline;
        border-bottom: 2px solid $warning-color;
    }

    &--error {
        @extend .feedback--outline;
        border-bottom: 2px solid $error-color;
    }

    &.sm {
        font-size: 13px!important;
        font-weight: 500;
        height: unset;
      
        span {
            font-weight: 500!important;
            text-shadow: 0 0 0.2px;
            cursor: pointer;
        }
    }

    &--loading {
        @extend .feedback--outline;
        border-bottom: 2px solid $yellow;
        padding: 0 10px 0 3px;

        p {
            font-size: 14px;
        }

        span {
            display: inline-block;
            width: 20px;
            align-self: center;
            height: 20px;
            border: 3px solid $yellow;
            border-radius: 50%;
            border-top-color: transparent;
            margin: 0 10px 8px 5px;
            animation: spin 1s ease-in-out infinite;
            -webkit-animation: spin 1s ease-in-out infinite;
        }

        @media only screen and (max-width: $sm-devices) {
            display: inline-block;
            margin: 20px 0 10px 0;
            padding: 0;
            border-bottom: none;
        }

        &.borderless {
            border: none;
        }
    }
}

.error__container {
    display: block;
    text-align: left;

    &--padded {
        padding: 10px 20px 10px 20px;

        @media only screen and (max-width: $sm-devices) {
            padding: 0;
        }
    }

    &--important {
        padding: 10px 0 3px 0;
        margin-bottom: 7px;
        border-bottom: 2px solid $error-color;

        @media only screen and (max-width: $md-plus-devices) {
            margin: 0 20px 7px 20px;
        }
    }

    &--centered {
        text-align: center;
        width: fit-content;
    }

    &--centeredSmall {
        @media only screen and (max-width: $sm-devices) {
            text-align: center;
        }
    }

    &--topped {
        margin-top: 20px;
    }

    &--inline {
        display: inline-block;
    }

    &--fitContent {
        display: block;
        width: fit-content;
    }

    &__text {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        color: $form-error;
    }
}