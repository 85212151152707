.pagination {
    &__list {
        @include clean-lists;
        margin: 14px 3px 10px 0;
        text-align: right;

        &__item {
            display: inline-block;
            font-family: $heading-font;
            font-size: 16px;
            font-weight: 500;
            padding: 1px 4px;

            &--active {
                background-color: $blue-grey;
                border-radius: 30px;
                margin: 0 3px;
                
                span {
                    color: white;
                }

                &:hover {
                    background-color: $yellow;
                    span {
                        color: $blue-grey;
                    }
                }
            }

            &.disabled {
                span {
                    cursor: context-menu;
                    color: $light-blue-grey;
                }
            }

            span {
                cursor: pointer;
                margin: 0 3px;

                &:not(.controls) {
                    text-shadow: 0 0 0.2px;
                }
                 
                &.controls {
                    font-weight: 500;
                    margin: 0 6px;
                }
            }
        }
    }

}
