.board {
    font-family: $text-font;
    display: grid;
    gap: 10px;
    grid-template-rows: 150px auto;
    background-color: $light-blue-grey;
    background-color: #f3f5f5;
    padding: 40px 60px 60px 60px;
    height: 100%;
    overflow: overlay;

    &__header {
        grid-column: 1 / span 3;
        grid-row: 1;
        text-align: left;

        h1 {
            text-transform: uppercase;
            margin: 0;
            padding-top: 10px;
            font-size: 45px;
        }

        &__summary {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 30px 0 10px 0;

            p:first-child {
                display: inline-block;
                margin: 0;
                font-size: 19px;
                font-weight: 500;
                line-height: 1.6;

                @media only screen and (max-width: $md-plus-devices) {
                    display: inline-block !important; 
                }
            }

            ul {
                @include clean-lists;
                display: inline-block;
                color: $blue-grey;

                li {
                    display: inline-block;
                    font-size: 16px;
                    margin: 0 0 6px 20px;
                    padding: 5px 10px;
                    border-radius: 15px;
                    @include user-select;

                    span {
                        font-weight: 600;
                    }

                    @media only screen and (max-width: $md-plus-devices) {
                        margin: 0 10px 10px 0;
                    }
                }
            }
        }

        @media only screen and (max-width: $md-plus-devices) {
            grid-column: 1 / span 3;
            grid-row: 1;

            &__summary {
                display: block;
                padding: 10px 0 0 0;

                p:first-child {
                    display: block;
                    margin: 8px 0 14px 0;
                    font-weight: 500;
                }

                ul {
                    display: block;

                    li {
                        display: inline-block;
                        margin: 5px 20px 0 0;
                        padding-left: 0;
                    }
                }
            }
        }

        @media only screen and (max-width: $xs-devices) {
            grid-column: 2 / span 2;
            grid-row: 2;
            text-align: center;
    
            &__summary {
                p:first-child {
                    font-size: 17px;
                }
            }
        }
    }

    &__clock {
        grid-column: 4;
        grid-row: 1 / span 1;
        text-align: center;

        &--display {
            text-transform: uppercase;
            margin: 0;
            font-size: 50px;
            background-color: $white;
            padding: 10px 20px;
            width: 242px;
            border-radius: 50px;
            display: inline-flex;
            box-shadow: $box-shadow;
        }

        @media only screen and (max-width: $md-plus-devices) {
            grid-column: 4 / span 1;
            grid-row: 1;
            text-align: right;

            &--display {
                font-size: 36px;
                width: 168px;
                padding: 8px 0 0 0;
                background-color: unset;
                box-shadow: none;
            }
        }

        @media only screen and (max-width: $xs-devices) {
            grid-column: 2 / span 2;
            grid-row: 1;
            text-align: center;

            &--display {
                display: inline-block;
                width: unset;
                font-size: 36px;
            }
        }
    }

    &__menu {
        text-align: center;
        grid-column: 4;
        grid-row: 2 / span 1;
        position: relative;
        min-height: 580px;

        &__buttons {
            text-align: center;

            h3 {
                font-size: 28px;
                font-weight: 500;
                margin: 0 0 35px 0;
            }

            button {
                width: fit-content;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                box-shadow: $box-shadow-card !important;
            }

            .button {
                border-radius: 3px;
                padding: 12px 12px;
                box-shadow: $box-shadow-card;
                font-weight: 400;
                color: $yellow;

                &--xl {
                    font-size: 15px;
                    font-weight: 500;
                }
            }

            @media only screen and (max-width: $md-plus-devices) {
                h3 {
                    margin: 0;
                    padding-bottom: 10px;
                }

                .button {
                    font-size: 13px;
                    padding: 8px 8px;
                }
            }
        }

        &__links {
            margin-top: 60px;

            a {
                text-decoration: none;

                &:hover {
                    text-shadow: 0 0 0.2px;
                }
            }

            p {
                display: block;
                width: 100%;
                font-size: 16px;
                font-family: $heading-font;
                font-weight: 500;
            }

            > a > p > img {
                margin-right: 6px;
                height: 30px;
                vertical-align: middle;
            }
        }

        @media only screen and (max-width: $md-plus-devices) {
            grid-column: 1 / span 4;
            grid-row: 2;
            display: inline-grid;
            min-height: unset;

            &__buttons {
                text-align: center;
                margin-top: 30px;

                h3 {
                    margin: 0;
                    display: block;
                    font-size: 20px;
                }

                button {
                    display: inline-block;
                    margin: 5px 10px 0 0;
                    padding: 10px 16px;
                    width: auto;
                }
            }

            &__links {
                order: -1;
                text-align: right;
                display: flex;
                justify-content: space-between;
                text-align: left;
                height: 20px;
                margin-top: 0;

                > a > p > img {
                    height: 20px;
                    margin-right: 2px;
                }

                > p {
                    display: inline-block;
                    margin: 10px 0 0 0;
                    padding: 0;
                    font-size: 14px;

                    &:nth-child(2) {
                        text-align: right;
                    }
                }

                @media only screen and (max-width: $md-plus-devices) {
                    display: none;
                }
            }
        }

        @media only screen and (max-width: $xs-devices) {
            grid-row: 3;

            h3 {
                font-size: 16px;
            }
        }
    }

    &__content {
        grid-column: 1 / span 3;
        grid-row: 2;
        text-align: center;
        position: relative;
        border-radius: 5px;
        background-color: $white;
        border: 1px solid rgb(216, 216, 216);
        box-shadow: $box-shadow-card;

        > .feedback, .board__error {
            @include absolute-center;
            top: 45%;
            color: $blue-grey;
        }

        .board__error {
            font-weight: 600;
            font-size: 16px;
        }

        @media only screen and (max-width: $md-plus-devices) {
            grid-column: 1 / span 4;
            grid-row: 3;
        }

        @media only screen and (max-width: $xs-devices) {
            grid-row: 4;
        }
    }

    &__table {
        text-align: left;
        color: $yellow;
        font-family: $heading-font;
        border-collapse: collapse;
        width: 100%;
        border-radius: 5px 5px 0 0;
        overflow: hidden;

        &__head {
            tr {
                background-color: #202a32;
                color: $white;

                th {
                    font-weight: 400;
                    font-size: 18px;

                    &:nth-child(1) {
                        padding: 10px 8px;
                    }
                }
            }
        }

        &__body {
            tr {
                border-bottom: 10px solid $white;
                color: $blue-grey;

                td {
                    padding-left: 2px;
                    font-size: 15px;

                    &:nth-child(1) {
                        text-transform: uppercase;
                        text-shadow: 0 0 0.2px;
                        padding-left: 10px;

                        p {
                            color: $blue-grey;
                        }
                    }

                    h4 {
                        font-size: 26px;
                        margin: 20px 0;
                    }
                }
            }
        }

        &__item {
            &--next {
                font-size: 19px;
                text-shadow: 0 0 0.2px;
            }

            &.upcoming__delivery {
                background-color: $blue-grey;
                height: 80px;

                h4,
                p,
                td {
                    color: $yellow !important;
                }

                @media only screen and (max-width: $md-plus-devices) {
                    h4 {
                        font-size: 20px;
                    }
                }
            }

            &.current__delivery {
                background-color: $yellow;
                height: 110px;

                td {
                    font-size: 17px;
                }

                h4 {
                    font-size: 28px;
                }
            }
        }
    }

    @media only screen and (max-width: $md-plus-devices) {
        padding: 20px;
        grid-template-rows: 50px 150px auto;

        h1 {
            font-size: 34px;
        }

        &__header {
            &__summary {
                p:first-child {
                    font-size: 1px;
                    color: transparent;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    @media only screen and (max-width: $xs-devices) {
        grid-template-rows: 50px 35px 200px auto;

        h1 {
            font-size: 26px;
        }
    }
}
