.split {
    font-family: $text-font;
    display: grid;
    gap: 0 10px;
    grid-template-rows: auto auto;

    &__left {
        grid-column: 1 / span 4;
        grid-row: 1;
        text-align: left;

        @media only screen and (max-width: 1450px) {
            grid-column: 1;
        }
    }

    &__right {
        grid-column: 5 / span 1;
        grid-row: 1;
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 1450px) {
            grid-row: 2;
            grid-column: 1;
            margin: 20px 0 0 0;
        }

        &__container {
            width: 400px;
            background-color: $grey;
            padding: 30px 30px 20px 30px;
            text-align: left!important;
            align-self: end;
            box-shadow: $box-shadow-light;
            border-radius: 4px;
            
            h2 {
                margin: 0 0 5px 0;
                font-size: 22px;

                @media only screen and (max-width: 1700px) { 
                    font-size: 22px;
                }
            }

            .summary__table {
                &__row {
                    text-align: left!important;
                    display: flex;

                    p {
                        width: 45%;
                        margin: 0 0 5px 0;
                        font-size: 15px;

                        &:nth-child(3) {
                            width: 10%;
                        }
                    }

                    &--header {
                        text-transform: uppercase;

                        p {
                            font-weight: 500;
                            text-shadow: 0 0 0.2px;
                            margin: 26px 0 10px 0;
                        }
                    }  
                }

                &__content {
                    display: block;

                    &--item {
                        width: 100%;
                        display: flex;

                        p {
                            width: 45%;
                            display: inline-block;
                            margin: 0 0 5px 0;

                            &:nth-child(3) {
                                width: 10%;
                                font-size: 12px;
                                cursor: pointer;
                                font-weight: 600;
                            }
                        }
                    }
                }

                &__dryRun {
                    margin: 20px 0 0 0;

                    img {
                        display: inline-block;
                        margin-right: 10px;
                        height: 28px;
                    }

                    &--header {
                        display: flex;
                    }

                    &--content {
                        display: block;
                        width: 100%;
                        min-height: 100px;
                        padding: 20px 8px 0 8px;

                        >.container {
                            display: flex;
                            width: 100%;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            max-height: 220px;
                            overflow: auto;

                            p:nth-child(1) {
                                margin: 0;
                                font-size: 15px;
                                font-weight: 500;
                                text-shadow: 0 0 0.2px;
                            }
                            
                            > div {
                                width: 30%;
                                background-color: $white;
                                cursor: context-menu;
                                padding: 10px 16px;
                                margin: 20px 10px 0 0;
                                text-align: center;
                                border-radius: 4px;
                                box-shadow: $box-shadow;
    
                                p {
                                    margin: 10px 0 0 0;
                                    font-size: 13px;
    
                                    &:nth-child(1) {
                                        font-size: 14px;
                                        margin-top: 0;
                                        font-weight: 500;
                                    }
                                }
                            }

                            @media only screen and (min-width: 2000px) { 
                                max-height: 400px;
                            }
                        }
                    }

                    &--banner {
                        padding: 6px 8px;
                        background: #3b454e;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 3px;
                        
                        p {                            
                            color: white;
                            font-weight: 400;
                            font-size: 18px;
                            font-family: $heading-font;
                            margin: 0;
                        }
                    }

                    &--btn {
                        color: $white;

                        &:hover {
                            color: $yellow;
                        }
                    }
                }
            }

            @media only screen and (min-width: 2000px) { 
                margin-bottom: 220px;
                width: 600px;
            }

            @media only screen and (max-width: 1450px) {
                width: 100%;
                float: none;
                padding: 40px 52px 36px 52px;

                .summary__table {
                    &__row {
                        max-width: 500px;
                        
                        p {
                            font-size: 16px;
                        }
                    }
    
                    &__content {
                        max-width: 500px;

                        &--item {
                            p {
                                font-size: 15px;
                            }
                        }
                    }

                    &__dryRun {
                        &--content {
                            >.container {
                                div {
                                    width: 18%;
                                }
                            }
                        }
                    }
                }

                @media only screen and (max-width: $md-plus-devices) {                
                    padding: 26px 20px;
                }

                @media only screen and (max-width: $sm-devices) {                
                    padding: 12px 15px 14px 15px;

                    .summary__table {
                        &__dryRun {
                            &--content {
                                >.container {
                                    div {
                                        width: 46%;

                                        @media only screen and (max-width: $xs-devices) { 
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__notice {
            margin: 30px 0;
            background-color: $light-yellow;
            padding: 10px 25px;
            display: flex;
            align-items: center;
            width: 520px;
            align-self: end;
            box-shadow: $box-shadow-light;
            position: absolute;
            bottom: 0;
            border-radius: 4px;

            img {
                height: 32px;
                margin-right: 14px;
            }

            div {
                p {
                    font-weight: 400;

                    &:nth-child(1) { 
                        font-weight: 600;
                    }
                }
            }

            @media only screen and (min-width: 2000px) { 
                width: 600px;
            }

            @media only screen and (max-width: 1680px) {
                width: 400px;
                padding: 5px 10px;
            }

            @media only screen and (max-width: 1450px) {
                width: 100%;
                float: none;
                position: unset;
            }
        }
    }
}

.notice {
    padding: 16px 16px 12px 16px !important;
    width: 100%;
    max-width: 650px;
    background-color: $grey;
    padding: 30px 30px 20px 30px;
    text-align: left!important;
    align-self: end;
    box-shadow: $box-shadow-light;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin: 40px 0 20px 0;
    
    h2 {
        margin: 0 0 5px 0;
        font-size: 22px;

        @media only screen and (max-width: 1700px) { 
            font-size: 22px;
        }
    }

    img {
        height: 32px;
        margin-right: 16px;
    }

    p {
        margin-left: 0 !important;
        font-size: 14px !important;
    }

    @media only screen and (max-width: $md-plus-devices) {                
        max-width: unset;

        @media only screen and (max-width: $sm-devices) {                
            display: block;
            text-align-last: center;
        }
    }
}

.equal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-top: 10px;

    &__part {
        position: relative;
        width: 48%;
        min-height: 380px;
 
        h2 {
            background-color: $blue-grey;
            color: white;
            border-radius: 3px;
            padding: 8px 5px;
        }

        > .loading__container {
            margin-top: 80px;
        }
    }

    @media only screen and (min-width: 2000px) {
        &__part {
            width: 45%;
            max-width: 1800px;
        }
    }
    
    @media only screen and (max-width: 1300px) {
        display: block;
        padding-top: 5px;

        &__part {
            width: 100%!important;
            max-width: unset;
            margin-bottom: 25px;
            padding-top: 10px;
            position: relative;
            min-height: unset;
        }
    }
}