input, select, option, ::placeholder, datalist {
    font-size: 14px;
    font-family: $text-font;
    color: $blue-grey;
    background-color: white;
}

input:disabled, select:disabled, input:disabled > ::placeholder {
    background-color: white;
    color: -internal-light-dark(graytext, rgb(170, 170, 170));
    opacity: 0.8;
    border-color: rgba(167, 167, 167, 0.3);
    cursor: not-allowed!important;
}

input[type="checkbox"] {
    accent-color: $blue-grey;
    height: unset;
    @include transform(scale(1.3));
}

input {
    border-radius: 3px;
    outline: none;
    border: 1px solid rgba(118, 118, 118, 0.3);
    height: 28px;
    padding: 2px 5px;
    cursor: pointer;
    font-family: $text-font;

    &:focus::placeholder {
        color: transparent!important;
    }

    &.input--error {
        border-color: $error-color!important;
    }
}

.error-text {
    color: $form-error;
    font-weight: 400;
}

.checkbox {
    vertical-align: middle;
    height: auto;
}

label.container {
    display: inline!important;
    position: relative;
    padding-left: 25px;
    margin-right: 26px;
    padding-top: 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: sub;

    &:nth-child(3) {
        margin-right: 0;
    }

    &:hover input ~ .checkmark:not(.disabled) {
        background-color: $yellow;
        box-shadow: $box-shadow-card;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 19px;
        width: 19px;
        background-color: white;
        border-radius: 50%;
        box-shadow: $box-shadow-light;
        
        &:not(.checked) {
            border: 1px solid $blue-grey;
        }

        &.disabled {
            background-color: $grey;
            cursor: not-allowed;
            border: 1px solid  #c1c0c0;
        }

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:not(.disabled):after {
            top: 5px;
            left: 5px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: white;
        }
    }

    input:checked ~ .checkmark:not(.disabled) {
        background-color: $blue-grey;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    &:has(input:disabled) {
        cursor: not-allowed;
    }
}