#modalOverlay {
    display: contents;
}

.backdrop {
    @extend .confirm__modal__overlay;
}

.login__modal {
    @extend .confirm__modal__dialog;
    max-width: 650px;
    text-align: center;

    &__content {
        &--header {
            padding-top: 20px;

            img {
                width: 30px;
            }

            h1 {
                margin-bottom: 35px;
            }

            p {
                font-size: 14px;
                margin: 5px 0;

                @media only screen and (max-width: $md-devices) {
                    display: inline;
                    margin-right: 5px;
                }
            }
        }

        form {
            margin: 10px 0;
            display: inline-block;
            text-align: center;
            width: 60%;

            @media only screen and (max-width: $sm-devices) {
                width: 100%;
            }

            .form__item {
                font-family: "Quicksand", sans-serif;
                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: $sm-devices) {
                    display: inline-block;
                    width: 100%;
                    margin-top: 15px;
                }

                &__label {
                    font-size: 15px;
                    font-weight: 500;
                    color: $blue-grey;
                    display: inline-block;

                    &--lg {
                        width: 42%;
                        max-width: 150px;
                    }
                }

                input::placeholder {
                    font-size: 13px;
                    font-style: italic;
                }

                &__step {
                    padding: 0;
                    width: 100%;
                    max-width: 250px;

                    @media only screen and (max-width: $sm-devices) {
                        max-width: unset;
                        margin-top: 10px;
                    }
                }
            }

            > .feedback {
                margin-top: 45px;
                display: inline-table;

                @media only screen and (max-width: $md-plus-devices) {
                    margin-top: 40px;
                }
            }
        }

        &--footer {
            text-align: center;
            padding: 0 10px;
            margin: 15px 0 0 0;

            @media only screen and (max-width: $md-devices) {
                margin: 15px 0 0 0;
                padding: 0 5px;
            }
        }
    }
}

.admin__modal {
    @extend .confirm__modal__dialog;
    max-width: 750px;
    padding: 0;

    @media only screen and (max-width: $lg-devices) {
        max-width: 550px;
        top: 53%;
    }
}
