.footer {
    position: relative;
    margin-top: auto;
    display: flex;
    width: 100%;
    text-align: left;
    padding: 20px 100px;
    box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.144);

    @media only screen and (max-width: $md-plus-devices) {
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 20px 40px;
    }

    @media only screen and (max-width: $sm-devices) {
        padding: 10px 20px;
    }

    &__section {
        align-self: center;
        min-width: 33.33%;

        @media only screen and (max-width: $md-plus-devices) {
            width: 50%;
            margin-top: 6px;

            &:nth-child(1) {
                width: 100%;
            }
        }

        @media only screen and (max-width: $sm-devices) {
            width: 100%;
        }


        p:not(.footer__section--logo) {
            margin: 8px 0;

            span {
                font-weight: 500;
            }

            @media only screen and (max-width: $md-devices) {
                margin: 6px 0;
                font-weight: 500;
                font-size: 13px;

                span {
                    font-weight: 600;
                }
            }
        }
        
        &--logo {
            font-size: 17px;
            font-weight: 500;

            @media only screen and (max-width: $md-devices) {
                font-size: 15px; 
            }
        }
    }
}
