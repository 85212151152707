.nav {
    position: relative;
    display: flex;
    background-color: $white;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 30px 100px;
    box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.144);

    @media only screen and (max-width: $md-plus-devices) {
        padding: 40px 40px 30px 40px;
    }

    @media only screen and (max-width: $md-devices) {
        padding: 10px 40px 25px 40px;
    }

    @media only screen and (max-width: $sm-devices) {
        padding: 10px 20px 20px 20px;
    }

    &__section {
        width: 50%;
        margin-bottom: 30px;
        align-self: center;

        @media only screen and (max-width: $md-devices) {
            width: 100%;
            margin-bottom: 20px;
        }

        &__logo {
            display: flex;
            text-decoration: none;
            align-items: center;

            img {
                display: inline-block;
                height: 60px;
            }

            @media only screen and (max-width: $md-devices) {
                justify-content: center;

                img {
                    display: none;
                } 
            }
            
            div {
                display: inline-block;
                padding-left: 5px;

                @media only screen and (max-width: $md-plus-devices) {
                    padding-left: 0;
                }

                @media only screen and (max-width: $md-devices) {
                    text-align: center;

                    h1 {
                        font-size: 28px;
                    }
                }

                p {
                    @include no-spacing;
                    margin: 5px 0;
                    font-size: 13px;
                }

                h1 {
                    @include no-spacing;
                    display: inline;
                }
            }
        }

        &__actions {
            display: inline-block;
            @include no-spacing;
            cursor: pointer;
            font-size: 15px;
            font-weight: 500;

            &:nth-child(1) {
                margin-right: 30px;
                font-weight: 500;
                font-size: 17px;
                font-family: $heading-font;

                @media only screen and (max-width: $md-devices) {
                    font-size: 15px;
                }
            }

            &:hover {
                text-shadow: 0 0 0.2px;
            }
        }

        &--access {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            div {
                display: flex;
                align-items: flex-end;
            }

            @media only screen and (max-width: $md-devices) {
                order: -1;
                margin-bottom: 0px;
                text-align: right;
                justify-content: space-between;

                &.reverse {
                    flex-direction: row-reverse;
                }

                img {
                    height: 24px;
                }

                p {
                    font-size: 14px;
                }
            }
    
            @media only screen and (max-width: $xs-devices) {
                margin-bottom: 16px;
            }
        }

        &__icon {
            height: 28px;
            margin-right: 6px;

            &--logo {
                height: 40px;
            }
        }
    }

    &__navigation {
        width: 100%;
        display: block;
        margin: 10px 52px 0 52px;

        @media only screen and (max-width: $md-plus-devices) {
            margin: 10px 48px 0 48px;
        }

        @media only screen and (max-width: $md-devices) {
            text-align: center;
            display: flex;
            justify-content: space-around;
            margin: 10px 0 0 0;
        }

        &__ul {
            display: inline-block;
            @include clean-lists;
            text-align: left;

            &--item {
                display: inline-block;
                margin: 0 55px 10px 0;

                a {
                    display: inline-block;
                    color: $blue-grey;
                    text-decoration: none;
                    font-size: 16px;
                    padding-bottom: 1px;
                    font-weight: 500;
                    border-bottom: 2px solid transparent;

                    &:hover {
                        text-shadow: 0 0 0.2px;
                        border-bottom: 2px solid $blue-grey;
                    }

                    &.active--nav {
                        text-shadow: 0 0 0.5px;
                        border-bottom: 2px solid $blue-grey;

                        &:hover {
                            color: $yellow;
                            border-bottom: 2px solid $yellow;
                        }
                    }
                }

                @media only screen and (max-width: $md-devices) {
                    margin: 0 20px 10px 20px;
                }

                @media only screen and (max-width: $sm-devices) {
                    margin: 0 6px 10px 6px;

                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
