$white: #ffffff;
$grey: #f7f7f7;
$yellow: #f2c139;
$light-yellow: #fdffb8; 
$transparent-yellow: #fdfd9679;
$blue-grey: #3b454e;
$light-blue-grey: #c9d1db;  
$form-error: rgb(190, 18, 18);
$error-color: rgba(221, 74, 74, 0.507);
$warning-color: rgba(248, 182, 38, 0.507);
$success-color: rgba(99, 161, 99, 0.293);

$heading-font: "Work Sans", sans-serif;
$text-font: "Quicksand", sans-serif;

$box-shadow-card: 4px 5px 8px -5px #0000005c;
$box-shadow: 0px 8px 10px -10px rgba(0, 0, 0, 0.25);
$box-shadow-light: 0px 5px 6px -6px rgba(0, 0, 0, 0.25);

$xs-devices: 400px;
$sm-devices: 600px;
$md-devices: 768px;
$md-plus-devices: 992px; 
$lg-devices: 1200px;

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    margin: 0;
}

body,
#root {
    margin: 0;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1,
h2,
h3,
h4 {
    font-family: $heading-font;
    color: $blue-grey;
    font-weight: 400;
}

h1 {
    font-size: 26px;
    font-weight: 500;
}

h2 {
    font-size: 18px;
    font-weight: 500;
}

h3,
h4 {
    font-size: 15px;
}

p, label {
    font-family: $text-font;
    color: $blue-grey;
    font-size: 15px;
    font-weight: 400;
}

span {
    color: $blue-grey;
    font-family: $text-font;

    &.bold {
        @extend p;
        font-weight: 500;
    }

    &.required {
        font-weight: bold;
        font-size: 15px;
        color: $form-error;
    }
}

a {
    color: $blue-grey;
    @extend p;

    &.action {
        font-weight: 500;
        text-decoration: none;

        &:hover {
            text-shadow: 0 0 0.2px;
        }
    }
}

.d-block-center {
    display: block;
    margin-right: 0 auto;
    margin-left: 0 auto;
    max-width: unset;
}

.trimmed {
    margin: 0;
    padding: 0;
}

.centered-text {
    text-align: center;
}

.topped {
    margin-top: 20px!important;
}

.no-mt {
    margin-top: 0;
}

.full-width {
    width: 100%;
}

.disabled_text {
    opacity: 0.5;
    cursor: not-allowed;
}

.pointer {
    cursor: pointer!important;
}

.empty-result {
    font-family: $heading-font;
    border-bottom: 3px solid $warning-color;
    font-weight: 500;
    padding-bottom: 3px;
    display: inline-block;
    cursor: context-menu;

    > a {
        font-family: inherit;
        font-weight: inherit;
        font-style: italic;
        text-decoration: none;
    }
 
    &--positive {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        display: block;

        &.offset {
            margin: 0;
        }
    }

    &--lg {
        margin-top: 60px;
    }

    @media only screen and (max-width: $md-plus-devices) {
        &.offset {
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media only screen and (max-width: $md-devices) {
        text-align: center;
        border: none;
    }

    @media only screen and (max-width: $sm-devices) {
        line-height: 1.4;
        font-weight: 400;
    }
}