.main {
    flex-grow: 1;

    &__section {
        background-color: $light-yellow;
        padding: 80px 100px;
        height: 100%;

        @media only screen and (max-width: $md-plus-devices) {
            padding: 40px 40px;
        }

        @media only screen and (max-width: $sm-devices) {
            padding: 30px 20px;
        }

        &__body {
            font-family: $text-font;
            background-color: $white;
            text-align: center;
            padding: 60px;
            height: 100%;
            position: relative;
            box-shadow: 0px 3px 6px -6px rgba(0, 0, 0, 0.185);

            @media only screen and (max-width: $md-plus-devices) {
                padding: 40px;
            }

            @media only screen and (max-width: $sm-devices) {
                padding: 20px 20px 30px 20px;
            }
        }

        .content {
            &__header {
                h1, p {
                    margin: 0 0 35px 0;
                    padding: 0;
                }

                p {
                    font-size: 15px;
                    
                    &.note {
                        line-height: 1.5;
                    }
                }

                @media only screen and (max-width: $md-devices) {
                    h1 {
                        font-size: 24px;
                        margin-top: 10px;
                    }
                }

                @media only screen and (max-width: $sm-devices) {
                    h1 {
                        font-size: 20px;
                        word-break: break-word;
                    }
                }
            }

            &__container {
                text-align: center;
                display: inline-block;
                max-width: 1200px;
                width: 100%;
                min-height: 100px;

                &__subheader {
                    text-align: center;
                    width: 100%;
    
                    div {
                        padding: 40px 30px 30px 30px;
                    }
    
                    p {
                        font-size: 15px;
                        margin-top: 6px;
    
                        a {
                            margin-left: 5px;
                            font-weight: 500;
                        }
                    }
    
                    .content__body--text {
                        div {
                            display: inline-block;
                            width: 100%;
                            max-width: 900px;
                            padding: 0 0 30px 0;
    
                            @media only screen and (min-width: 2000px) {
                                max-width: 1300px;
                            }
    
                            &:last-child {
                                padding-bottom: 0;
                            }
    
                            p {
                                width: 100%;
                                display: initial;
                            }
                        }
                    }
    
                    button {
                        margin-top: 20px;
                    }
                }

                &__body {
                    padding: 30px 0 20px 0;
                    position: relative;
                    min-height: 300px;
    
                    &--text {
                        max-width: 1000px;
                        text-align: center;
                        display: inline-block;
                        padding-bottom: 20px;

                        div {
                            p.important {
                                text-shadow: 0 0 0.2px;
                            }

                            span.bold {
                                font-weight: 500;
                                font-style: italic;
                                
                                &.limit {
                                    border-bottom: 2px solid $yellow;
                                    display: inline;
                                }
                            }
    
                            img {
                                height: 24px;
                                vertical-align: middle;
                                margin: 0 5px 5px 0;
                            }
                        }
                    }
    
                    &--details {
                        text-align: left;
                    }
                }

                @media only screen and (min-width: 2000px) {
                    max-width: 1600px;
                }
            }

            &__body {
                text-align: left;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;

                &--square {
                    width: 50%;

                    h2 {
                        font-size: 16px;
                    }

                    &:nth-child(1),
                    &:nth-child(3) {
                        padding-right: 25px;
                    }

                    &:nth-child(2),
                    &:nth-child(4) {
                        padding-left: 25px;
                    }

                    @media only screen and (max-width: $md-devices) {
                        width: 100%;
                        padding: 0!important;
                        text-align: center;
                    }

                    @media only screen and (min-width: 2000px) {
                        &:nth-child(1),
                        &:nth-child(3) {
                            padding-right: 50px;
                        }
    
                        &:nth-child(2),
                        &:nth-child(4) {
                            padding-left: 50px;
                        }
                    }
                }
            }
        }
    }
}
