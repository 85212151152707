@mixin clean-lists {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin no-spacing {
    padding: 0;
    margin: 0;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin cover-background {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; 
} 

@mixin cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin center-background {
    background-repeat: no-repeat;
    background-position: center;
}

@mixin absolute-center-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin user-select {
    -webkit-user-select: none; 
    -moz-user-select: none;     
    -ms-user-select: none;      
    user-select: none;          
}

@mixin table-heading-action($align, $weight) {
    background-color: $yellow;
    color: $blue-grey;
    text-align: $align;
    font-weight: $weight;
}

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}