@keyframes spin {
    0% { 
        transform: rotate(0deg); 
    }
    100% { 
        transform: rotate(360deg); 
    }
}

@-webkit-keyframes spin {
    0% { 
        -webkit-transform: rotate(0deg); 
    }
    100% { 
        -webkit-transform: rotate(360deg); 
    }
}

@keyframes loadingLinear {
    0% {
        background-position: -300px 0;
    }
    100% {
        background-position: 300px 0;
    }
}

@-webkit-keyframes loadingLinear {
    0% {
        -webkit-backgound-position: -300px 0;
    }
    100% {
        -webkit-backgound-position: 300px 0;
    }
}

@keyframes drape {
    0%,
    10% {
        transform: translate(40px, -45px) rotate(-45deg);
    }
    90%,
    100% {
        transform: translate(0px, 0px) rotate(-45deg);
    }
}

@-webkit-keyframes drape {
    0%,
    10% {
        -webkit-transform: translate(40px, -45px) rotate(-45deg);
    }
    90%,
    100% {
        -webkit-transform: translate(0px, 0px) rotate(-45deg);
    }
}
