.config {
    display: inline-block;
    font-family: $text-font;
    color: $blue-grey;
    margin-top: 14px;

    > form {
        margin-bottom: 24px;
    }

    &__form {
        margin: 20px 0;

        input, select {
            border-radius: 3px;
            outline: none;
            border: 1px solid rgba(118, 118, 118, 0.3);
            height: 28px;
            display: inline-block;
            width: 160px;
            padding: 2px 5px;
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            font-family: $text-font;
            box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.25);

            &.input--error {
                border-color: $error-color;
                background-color: $transparent-yellow;
            }
        }

        &__step {
            display: flex;
            margin-bottom: 20px;
            position: relative;

            @media only screen and (max-width: $md-plus-devices) {
                display: block;
                margin-bottom: 30px;
            }

            div {
                display: inline-block;

                label {
                    font-weight: 500;
                    text-shadow: 0 0 0.2px;
                }

                p {
                    font-size: 14px;
                    opacity: 0.9;
                    margin: 5px 0 0 0;

                    &.inline--error {
                        width: 160px;
                        font-size: 13px;
                        font-weight: 500;
                        color: $form-error;

                        @media only screen and (max-width: $md-plus-devices) {
                            text-align: center;
                            width: 100%;
                            margin-top: 0;
                        }
                    }
                }

                &:nth-child(1) {
                    width: 620px;

                    @media only screen and (max-width: $lg-devices) {
                        width: 490px;
                    }
                    
                    @media only screen and (max-width: $md-plus-devices) {
                        width: 100%;
                        max-width: 600px;

                        p {
                            margin-bottom: 10px;
                        }
                    }
                }

                &:nth-child(2) {
                    padding-left: 70px;

                    @media only screen and (max-width: $lg-devices) {
                        padding-left: 30px;
                    }

                    @media only screen and (max-width: $md-plus-devices) {
                        padding: 0;

                        input, span {
                            margin: 10px 0;
                        }
                    }
                }
            }

            span {
                min-width: 40px;
                font-weight: 500;
                color: $blue-grey;
                font-size: 15px;
                text-shadow: 0 0 0.2px;
                margin: 7px 0 0 5px;
                line-height: 1.8;
            }

            &--input {
                p {
                    text-align: left;
                    margin: 8px 2px 10px 0;
                }

                @media only screen and (max-width: $md-plus-devices) {
                    width: 100%;
                }
            }

            &--confirm {
                margin: 10px 0 10px 0;

                div {
                    margin: 10px 0 0 3px;
                }
            }
        }
    }
}

.graintable {
    font-family: $text-font;
    color: $blue-grey;
    display: inline-block;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
        margin: 20px 0;
        width: 45%;

        &--header {
            display: flex;
            width: 100%;
            background-color: $blue-grey;
            color: white;
            justify-content: space-between;
            padding: 5px 8px 8px 5px;
            border-radius: 2px;

            h2 {
                color: white;
                display: inline-block;
                font-size: 20px;
                text-transform: capitalize;
                margin: 0 20px 0 0;
            }
    
            div {
                align-items: flex-end;
                display: inline-flex;
                cursor: pointer;
                padding-bottom: 3px;
    
                img {
                    height: 18px;
                    margin: 0 3px 1px 0;
                }
    
                span {
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }

        &--body {
            margin-bottom: 20px;
            padding: 0 3px 0 5px;

            > form {
                width: 100%;
                display: flex;
                align-items: flex-start;

                &.graintable__item--input {
                    padding: 16px 0 5px 0;

                    div {
                        min-height: 46px;
                    }
                }

                div {
                    width: 48%;
                    margin-right: 20px;
                    padding-top: 5px;

                    input {
                        min-width: 240px;
                        height: 30px;
                        display: inline-block;
                        margin-top: 8px;

                        &.input--error {
                            border-color: $error-color;
                            background-color: $transparent-yellow;
                        }
                    }

                    span {
                        font-size: 15px;
                    }

                    p {
                        display: inline-block;
                        font-weight: 500;
                        font-size: 16px;
                        text-shadow: 0 0 0.2px;
                        margin: 16px 0 5px 6px;
                    }

                    >.error-text {
                        margin: 6px 2px 10px 0;
                        font-size: 13px;
                        font-weight: 300;
                        color: $form-error;
                        display: block;
                    }
                }

                button {
                    display: inline-block;
                    height: fit-content;
                    margin-top: 18px;
                }

                @media only screen and (max-width: 1500px) { 
                    flex-direction: column;

                    div {
                        width: 100%;
                        margin: 0;
                        height: fit-content;

                        input {
                            float: right;
                            margin-top: 0;
                            min-width: 120px;
                        }
                    }
                }

                @media only screen and (max-width: $lg-devices) {
                    div {
                        padding: 10px 0 5px 0;

                        input {
                            display: block;
                            width: 100%;
                            margin: 8px 0 5px 0;
                        }
                    }
                }

                @media only screen and (max-width: $md-devices) {
                    flex-wrap: wrap;
                    justify-content: center;
                    text-align: left;
                    max-width: unset;

                    div {
                        width: 100%;
                        
                        input {
                            display: block;
                            width: 100%;
                        }

                        &:nth-child(2) > label {
                            margin-top: 10px;
                            display: inline-flex;
                        }
                    }
                }
            }

            div.feedback {
                padding-top: 8px;
            }

            @media only screen and (max-width: $md-devices) {
                margin-bottom: 35px;
            }
        }

        @media only screen and (max-width: 1500px) { 
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: $md-devices) {
        max-width: unset;
        flex-wrap: nowrap;
        flex-direction: column;

        &__item {
            width: 100%;
        }
    }
}

