.loading__container {
    position: relative;
    min-height: 150px;
}

.loader {
    font-family: $text-font;
    padding-bottom: 42px;
    text-align: center;
    @include absolute-center;

    &__container {
        position: relative;
        @include absolute-center;
        padding-bottom: 20px;
        width: max-content;

        .loader__wrapper {
            padding-top: 0;
        }
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 63px;
        padding: 20px 0;
        margin: 0 auto;
        text-align: center;
    
        .leaf {
            width: 30px;
            height: 30px;
            display: inline-block;
            position: relative;
            background-color: $grey;
            overflow: hidden;
    
            &:after {
                content: "";
                position: absolute;
                inset: 5px;
                margin: auto;
                background-color: $white;
            }
    
            &:before {
                content: "";
                position: absolute;
                margin: auto;
                background-color: $yellow;
            }
    
            &--left {
                margin-right: 2px;
                border-top-right-radius: 70% 60%;
                border-bottom-left-radius: 70% 60%;
                -moz-border-top-right-radius: 70% 50%;
                -moz-border-bottom-left-radius: 70% 50%;
    
                &:after {
                    border-top-right-radius: 70% 60%;
                    border-bottom-left-radius: 70% 60%;
                    -moz-border-top-right-radius: 70% 50%;
                    -moz-border-bottom-left-radius: 70% 50%;
                }
    
                &:before {
                    inset: -15px;
                    animation: drape 1.8s ease-in infinite;
                }
            }
    
            &--right {
                border-top-left-radius: 70% 60%;
                border-bottom-right-radius: 70% 60%;
                -moz-border-top-left-radius: 70% 50%;
                -moz-border-bottom-right-radius: 70% 50%;
    
                &:after {
                    border-top-left-radius: 70% 60%;
                    border-bottom-right-radius: 70% 60%;
                    -moz-border-top-left-radius: 70% 50%;
                    -moz-border-bottom-right-radius: 70% 50%;
                    inset: 20px;
                }
    
                &:before {
                    inset: -20px;
                    animation: drape 1.8s linear infinite;
                }
            }
        }
    }
    
    span {
        margin: 0 auto;
        padding-top: 8px;
        font-size: 15px;
    }
}
