.confirm__modal {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.651);
        background-color: #5b5b5bc9;
    }

    &__dialog {
        position: fixed;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        z-index: 6;
        padding: 20px 25px;
        background-color: white;
        width: 100%; 
        max-width: 600px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        box-shadow: $box-shadow-card;

        form > p {
            font-size: 16px;
            font-weight: 500;
        }
        
        &--buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
    
            > button.plain {
                margin-left: 10px;
            }
        }

        @media only screen and (max-width: $md-plus-devices) {
            width: 85%;
        }
    }
}