button {
    outline: none;
    border: 2px solid transparent;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 11px 18px;
    box-shadow: $box-shadow-light;
    cursor: pointer;
    font-family: $heading-font;
    font-weight: 500;
    font-size: 14px;
    
    &.button__mr {
        margin-right: 14px;
    }

    @media only screen and (max-width: $sm-devices) {
        padding: 10px 14px;
        border-radius: 18px;

        &.button__mr {
            margin: 0 14px 14px 14px;
        }
    }

    @media only screen and (max-width: $xs-devices) {
        &.button__mr {
            margin: 0 0 14px 0;
        }
    }

    &.text__btn {
        cursor: pointer!important;
        font-weight: 500!important;
        text-transform: none!important;
        font-family: $heading-font!important;
        background: none !important;
        box-shadow: none !important; 
        border: none !important;
        padding: 0!important;
        text-align: left;
        color: $blue-grey;
    
        &:disabled {
            cursor: not-allowed!important;
            color: #969696!important;

            > img {
                opacity: 0.6!important;
            }
        }

        &--exit {
            img {
                filter: invert(11%) sepia(80%) saturate(7481%) hue-rotate(1deg) brightness(100%) contrast(111%);
            }
        }
    }

    &.thin {
        padding: 8px 12px;
        font-size: 13px;
        font-weight: 400;
        margin-top: 5px;

        img {
            height: 18px;
            margin-right: 3px;
            vertical-align: sub;
        }

        &:hover {
            text-shadow: 0 0 0.2px;
            box-shadow: $box-shadow-card;
        }
    }

    &.confirm {
        background-color: $blue-grey;
        color: white;

        &:hover:not(:disabled) {
            background-color: $yellow;
            color: $blue-grey;
        }

        &:disabled {
            background-color: #3b454e2d;
            border: 2px solid #3b454e4f;
            color: #3b454e77;

            &:hover {
                color: #3b454e77;
            }

            &.blank {
                background-color: $yellow;
                border: 2px solid $yellow;
                color: $blue-grey;
                cursor: context-menu;
            }
        }
    }

    &.plain {
        background-color: $white;
        color: $blue-grey;
        border: 2px solid $blue-grey;

        &:hover:not(:disabled) {
            border-color: $yellow;
        }
    }

    &.active {
        background-color: $yellow;
        color: $blue-grey;
        border: 2px solid $yellow;

        &:hover:not(:disabled) {
            background-color: $light-yellow;
        }
    }

    &:hover {
        box-shadow: $box-shadow;
    }

    &:disabled {
        background-color: $grey;
        cursor: not-allowed;
        border: 2px solid $grey;
        color: rgb(150, 150, 150);
    }
}

.export__button {
    position: absolute;
    right: 0;
    top: 4px;
    font-size: 14px;
    word-spacing: 1px;
    padding: 8px 12px 6px 12px!important;
    border: 1px solid rgba(173, 173, 173, 0.612)!important;
    border-radius: 3px;
    box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.25)!important;
    background-color: white;
    color: $blue-grey;
    text-transform: none;

    &--padded {
        right: 14px;
        top: 13px;
    }

    &:disabled {
        img {
            opacity: 0.6;
        }
    }

    img {
        vertical-align: middle;
        margin-right: 6px;
        height: 20px;
        opacity: 0.9;
    }

    &:hover {
        text-shadow: 0 0 0.2px;
        border: 1px solid $yellow!important;
        box-shadow: $box-shadow-card;

        img {
            opacity: 1;
        }
    }

    @media only screen and (max-width: $md-devices) {
        right: unset;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        &--padded {
            left: 10px;
            margin-right: unset;
            transform: unset;
        }
    }
}

.feedback__container {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    justify-content: flex-start;

    @media only screen and (max-width: $sm-devices) {
        display: inline-table;

        button:nth-child(2) {
            margin: 20px 0 10px 0;
        }
    }
 
    .feedback--center {
        text-align: center;
        justify-content: center;
    }

    .feedback--xl {
        margin: 30px 0 20px 0;
    }

    p.confirmation-msg {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 30px;
    }
}

.button__clear {
    position: absolute;
    top: 23px;
    right: 5px;
    z-index: 3;
    padding: 0 0 0 20px;
    background: inherit;
    font-weight: 400;
    font-size: 18px;
    color: rgb(134, 134, 134);
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        color: $blue-grey;
    }
}