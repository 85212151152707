.table {
    font-family: $text-font;
    border-collapse: collapse;
    width: 100%;
    margin: 5px 0;
    color: $blue-grey;
    text-align: left;

    &--attendance {
        th:nth-child(10) {
            @include table-heading-action(center, 500);
        }
    }

    &--lg {
        th:nth-child(5),
        th:nth-child(6) {
            @include table-heading-action(center, unset);
        }

        td:nth-child(5),
        td:nth-child(6) {
            text-align: center;
        }
    }

    &--lg--plus {
        margin-top: 20px;
        th:nth-child(7),
        th:nth-child(8),
        th:nth-child(9) {
            @include table-heading-action(center, 500);
        }

        td:nth-child(7),
        td:nth-child(8),
        td:nth-child(9) {
            text-align: center;
        }
    }

    &--xl {
        th:nth-child(5),
        th:nth-child(6),
        th:nth-child(7),
        th:nth-child(8),
        th:nth-child(9) {
            @include table-heading-action(left, unset);
            padding-left: 18px;
        }

        th:nth-child(5),
        td:nth-child(6),
        td:nth-child(7),
        td:nth-child(8),
        td:nth-child(9) {
            padding-left: 18px;
        }
    }

    th,
    td {
        padding: 10px 8px;
        font-weight: 300;
    }

    td {
        font-size: 14px;
        padding-left: 11px;
        font-family: $heading-font;

        &.headspace {
            padding-top: 22px;

            a {
                font-weight: 600;
            }

            a,
            button {
                margin-left: 5px;

                &:hover:not(.disabled_text) {
                    text-decoration: underline;

                    img {
                        filter: invert(48%) sepia(79%) saturate(2476%)
                            hue-rotate(70deg) brightness(68%) contrast(119%);
                    }
                }
            }

            &.available {
                padding-left: 21px;

                a {
                    padding: 0 2px 6px 0 !important;
                    vertical-align: middle !important;
                    border-bottom: 1px solid transparent;
                }
            }
        }

        > .text__btn {
            align-items: center;
            display: inline-flex;
            font-family: $text-font;
            margin: 3px 0 0 0; 
            vertical-align: bottom;
            margin-top: 0px;
            color: $blue-grey;

            &.button__mr {
                margin: 3px 10px 0 0; 
            }

            &:disabled > img {
                opacity: 0.6;
            }

            img {
                height: 22px;
                margin-right: 5px;

                &.sm {
                    height: 22px;
                }
            }
        }

        a {
            align-items: center;
            display: inline-flex;
            text-decoration: none;

            img {
                height: 26px;
                margin-right: 5px;

                &.sm {
                    height: 22px;
                }
            }
        }

        span.note {
            font-weight: 500;
            font-size: 13px;

            font-family: $heading-font;
        }
    }

    th.table__head--main,
    td.table__row--main {
        font-weight: 500;
    }

    .td__center {
        text-align: center!important;
        padding-left: 10px!important;

        button img {
            margin: 0;
            text-align: center;
            height: 26px;
        }

        &.mass__actions {
            width: 188px;
        } 

        > button.mass__actions--btn {
            padding: 4px 5px 0px 5px;
            background-color: white;
            border: 1px solid #d7d7d79c;
            border-radius: 3px;
            height: 30px;
            max-height: 30px;
            width: 31px;
            max-width: 31px;
            text-align: center;
            display: inline-block;
            box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.25);
            overflow: hidden;

            &--mr {
                margin-right: 6px;
            }

            img {
                height: 17px;
            }

            &--left {
                float: left;
            }

            &:disabled {
                background-color: #f3f3f3;
                
                img {
                    opacity: 0.5;
                }
            }
            
            &:hover:not(:disabled) {
                background-color: white;
                border: 1px solid #cacaca9c;
                box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.349);
  
                img {
                    @include transform(scale(1.1));
                }
            }
        }

        &--checkbox {
            padding-left: 8px!important;
        }
    }

    .td__mass__actions {
        text-align: left!important;
        padding-left: 30px!important;
    }

    .td__right {
        @extend .td__center;
        text-align: right!important;
        padding-left: 35px !important;
        padding-right: 3px;
    }

    &--xxl {
        th:nth-child(10),
        th:nth-child(11),
        th:nth-child(12) {
            background-color: $yellow;
            color: $blue-grey;
            text-align: left;
            padding-left: 18px;
            font-weight: 500;
        }

        .tr__result {
            border-bottom: 0;

            td {
                padding: 0;
            }
        }

        td:nth-child(10),
        td:nth-child(11),
        td:nth-child(12) {
            padding-left: 20px;
            font-weight: 500;

            button {
                font-size: 15px;
            }
        }

        td {
            font-size: 14px;
            font-weight: 400;
        }
    }

    tbody {
        .table__row__reserved {
            background-color: $transparent-yellow;
            text-shadow: 0 0 0.2px;

            &:hover {
                cursor: context-menu;
                background-color: #fafa85;
            }

            td > p.empty-result {
                margin: 0;
                font-size: 14px;
            }
        }

        tr {
            height: 44px;
            border: 1px solid transparent;
            border-bottom: 1px solid #cfcfcf;

            &:nth-child(even):not(.table__row__reserved):not(.table__row__taken) {
                background-color: #fcfcfc85;
            }

            &:hover:not(.table__row__reserved):not(.table__empty):not(.table__row__taken),
            &:hover:nth-child(even):not(.table__row__reserved):not(.table__row__taken) {
                background-color: #b9cefa2d;
            }
        }

        .table__row__checked {
            background-color: #eaeaea!important;
            
            &:hover {
                cursor: context-menu;
                background-color: $light-blue-grey!important;
            }
        }

        
        .table__row__taken, .table__row__expired, .table__row__error  {
            background-color: #ffd1d1!important;
            border-bottom: none;

            td.taken, td.expired {
                padding-top: 0;
                border-bottom: 1px solid #cfcfcf;
            }
        }
    }

    tbody.table__reverse {
        tr {
            &:nth-child(even){
                background-color: white;
            }
            &:nth-child(odd){
                background-color: #f7f7f785;
            }

            &:hover:nth-child(odd) {
                background-color: #ebebeb85;
            }
        }
    }

    &__head {
        background-color: $blue-grey;
        color: $white;
        font-weight: 400;
        font-family: $heading-font;

        &--main {
            color: $yellow;
            text-transform: uppercase;
        }

        &--feedback {
            color: $yellow;
        }

        &--yellow {
            background-color: $transparent-yellow;
            text-align: left;
            color: $blue-grey;
            border-radius: 2px 2px 0 0;
            border-bottom: 1px solid #d3d3d3;

            th {
                text-align: left;
                padding: 15px 10px;
                font-size: 15px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }

    &__row {
        &--open {
            background-color: #fffcba !important;
            border: none !important;
        }

        &--inputs {
            background-color: #fffcba !important;

            td {
                vertical-align: top;

                &:nth-child(1) {
                    span { margin-right: 15px;}
                }

                div > label, p, a {
                    font-weight: 500;
                    font-size: 15px;
                }

                input.available,
                input.checkbox {
                    @media only screen and (max-width: 1450px) {
                        margin: 3px 0 0 0;
                    }
                }

                label {
                    margin: 0 10px 5px 0;
                }
            }

            &.available {
                td {
                    vertical-align: sub;
                    padding: 7px 10px 20px 10px;
                    font-weight: 500;

                    &.available {
                        label {
                            font-weight: 500;
                        }

                        p {
                            margin: 0;
                            vertical-align: top;
                            padding: 17px 0 0 15px;
                            font-weight: 500 !important;
                            font-family: $heading-font;
                        }
                    }
                }
            }
        }

        &--feedback {
            padding: 10px 10px 11px 10px !important;

            p {
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
            }
        }

        &--error {
            margin: 0 5px 0 0;
            color: $form-error;
            text-align: right;
            font-size: 14px;
        }

        &--icon {
            height: 20px;
            vertical-align: bottom;
            margin-right: 5px;
        }
    }

    @media only screen and (max-width: $md-devices) {
        min-width: 800px;
    }
}

.overview {
    position: relative;

    button.refresh {
        position: fixed;
        right: 0;
        left: 140px;
        bottom: 0;
        z-index: 99; 
        border: none; 
        outline: none; 
        background-color: $yellow; 
        color: $blue-grey; 
        cursor: pointer; 
        text-align: center;
        font-size: 14px;
        text-transform: capitalize;
        align-items: center;
        transition: .2s;
        padding: 10px 14px; 
        overflow: hidden;
        justify-content: center;
        border-radius: 0;
        height: 30px;
        border-top: 1px solid #b7b7b7;
        display: none;

        &.visible {
            display: inline-flex;
        }

        span {
            opacity: 0;
            max-height: 1em;
            white-space: nowrap;
            transition: .2s;
        }

        img {
            width: 20px; 
            transition: .2s;
            vertical-align: middle;
        }

        &:hover {
            display: flex;
            flex-direction: row;

            img {
                height: 18px;
            }

            span {
                padding-left: 6px;
                font-weight: 600;
                margin: 0 0 5px 4px;
                opacity: 1;
            }
        }
    }

    > h2:nth-child(3) {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 1px;
        word-spacing: 3px;
    }

    &__intro {
        display: none;
        height: 0;

        p {
            max-width: 1200px;
        }

        &.show {
            display: block;
            height: fit-content;
            background-color: #fff6db80;
            padding: 18px 20px;
            box-shadow: $box-shadow;

            .overview__intro__wrapper {
                display: flex;
                justify-content: space-between;
                gap: 15px;
                align-items: flex-start;
                flex-wrap: wrap;
                
                div {
                    width: 46%;
                }
            }

            h2 {
                font-size: 15px;
                margin-bottom: 0;
                padding: 5px 0 3px 0;
            }

            p {
                margin: 0 0 0 0;
                font-size: 13px;
                font-weight: 500;
                line-height: 1.7;

                img {
                    height: 20px;
                    vertical-align: sub;

                    &.sm {
                        height: 17px;
                    }
                }

                span.bold {
                    text-shadow: 0 0 0.2px;
                }

                span.yellow__marker {
                    background-color: $transparent-yellow;
                    padding: 0 3px
                }
            }
        }
    }

    &__banner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px 8px;
        background-color: #f3f3f3;
        box-shadow: $box-shadow-light;
        border-radius: 2px;
        align-items: center;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

        h1 {
            font-size: 26px;
            margin: 0;
        }

        input, select {
            width: auto;
            display: inline;
            height: 32px;
            margin-top: 6px;
            font-size: 14px;
        }

        label {
            display: block;
            font-weight: 500;
            font-size: 15px;
            font-family: $heading-font;
        }

        &__filter {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                margin-right: 15px;

                &:nth-child(2) {
                    margin-right: 0;
                }
            }

            &--dates, &--changes {
                select {
                    min-width: 200px;
                }
            }

            &--search {
                position: relative;

                input {
                    min-width: 230px;

                    &::placeholder {
                        opacity: 0.9;
                        font-size: 14px;
                    }
                }

                span.button__clear {
                    bottom: 6px;
                    top: unset;
                    right: 6px;
                    height: fit-content;
                    font-weight: 500;
                    background-color: inherit;
                }

                div.searchbar {
                    position: relative;
                    
                    input {
                        position: relative;
                        padding-left: 30px;
                    }
    
                    img {
                        position: absolute;
                        left: 6px;
                        height: 20px;
                        top: 13px;
                        opacity: 0.8;
                    }

                    input:disabled + img {
                        opacity: 0.5!important;
                    }
                }
            }
        }

        &__radio {
            #workflowDD {
                display: none;
            }

            div {
                display: block;
                min-height: 28px;
                margin-top: 9px;

                label.container {
                    margin-right: 20px;
                    font-size: 14px;
                }
            }
        }

        &__refresh {
            height: 56px;
            text-align: left;

            div {
                display: inline-block;
                margin-left: 20px;
                
                > label {
                    display: block;
                    font-weight: 500;
                    font-size: 15px;
                    font-family: $heading-font;
                    margin-bottom: 13px;
                }
                
                button {
                    font-size: 13px;

                    img {
                        margin-left: 4px;
                        height: 20px;
                        vertical-align: middle;
                        opacity: 0.9;

                        &.spin {
                            height: 16px;
                        }
                    }

                    &:hover {
                        text-shadow: 0 0 0.2px;

                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 1550px) {
            flex-direction: column;
            position: relative;

            h1 {
                align-self: flex-start;
                font-size: 22px;
            }

            &__filter {
                margin-top: 25px;
            }

            &__radio {
                padding: 20px 0 10px 0;
                display: flex;
                align-items: center;

                label:nth-child(1) {
                    margin: 0 25px 2px 0;
                    display: inline-block;
                }

                div {
                    display: inline;
                    margin: 0;

                    label.container {
                        .checkmark {
                            height: 16px;
                            width: 16px;

                            &:not(.disabled):after {
                                top: 4px;
                                left: 4px;
                                width: 8px;
                                height: 8px;
                            }
                        }
                    }
                }
            }
            
            &__refresh {
                position: absolute;
                right: 0;

                > label {
                    display: inline;
                    margin-right: 26px;
                    font-size: 17px;
                }
    
                &--container {
                    display: inline;
                }
            }
        }

        @media only screen and (max-width: $md-devices) {
            display: block;
            position: relative;

            h1 {
                text-align: left;
            }
            
            &__radio {
                max-width: unset;
                display: block;
                width: 100%;
                padding-top: 0;

                > label:nth-child(1) {
                     margin-right: 50px;
                }

                #workflowRadio {
                     display: none;
                }

                #workflowDD {
                     display: block;
                     width: 100%;
                }
            }
                
            &__filter {
                display: block;  
                width: 100%;

                &--search, &--dates {
                    width: 100%;
                    margin-bottom: 12px;

                    > input, select {
                        width: 100%!important;
                        min-width: unset!important;
                    }

                    div.searchbar {
                        input {
                            width: 100%;
                            min-width: unset!important;
                        }
                    }
                }
            }

            &__refresh {
                top: 0;
            }
        }

        @media only screen and (max-width: $sm-devices) {
            h1 {
                text-align: center;
            }

            &__filter {
                > div {
                    margin-right: 0;
                }
    
                &--radio {
                    @media only screen and (max-width: $xs-devices) {
                        display: inline-block;

                        > label:nth-child(1) {
                            display: block;
                            margin-top: 3px;
                        }
                    }
                }
            }
    
            &__refresh {
                display: none;
            }    
        }
    }

    &__menu {
        background-color: #f7f6e4;
        border: 1px dotted $blue-grey;
        margin: 30px 0 0 0;
        position: fixed;
        z-index: 10;
        box-shadow: rgba(0, 0, 0, 0.63) 0px 5px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px 76px 12px 202px;
        border-radius: 0;

        &--content {
            height: fit-content;

            div {
                border-radius: 2px;
                margin: 0 0 10px 0;

                button {
                    margin-left: 25px;

                    &:nth-child(4) {
                        margin-left: 8px;
                    }
                }

                label {
                    margin-right: 5px;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }

        &--buttons {
            span {
                text-shadow: 0 0 0.2px;
            }

            p:nth-child(1) {
                margin-top: 0;
            }

            p {
                font-weight: 500;
            }

            > div:nth-child(2) {
                margin-top: 10px;
            }

            button:not(.thin) {
                font-size: 16px;
                text-decoration: none;
                text-shadow: 0 0 0.2px;

                img {
                    vertical-align: middle;
                    height: 24px;
                    margin-right: 4px;
                }
            }

            .action {
                margin: 0 50px 5px 0;
                font-weight: 400!important;

                &:not(:disabled) {
                    opacity: 1;
                }

                &:disabled {
                    opacity: 0.85;
                }

                &:hover:not(:disabled):not(.text__btn--exit) {
                    opacity: 1;
                    text-shadow: 0 0 0.2px;
                }
            }
        }

        &--checkbox {
            display: block;
            padding: 0 0 16px 3px;

            label {
                font-size: 14px;
                margin-right: 40px;

                span {
                    text-shadow: 0 0 0.3px;
                    font-size: 16px;
                    font-weight: 500;
                    margin-right: 4px;
                }
            }

            input {
                margin: 0 10px 2px 0;
                cursor: pointer;
                vertical-align: middle;
            }
        }

        .feedback {
            > div {
                &.feedback--success {
                    border-color: rgba(99, 161, 99, 0.726);
                }

                > p {
                    font-weight: 400;
                    text-shadow: 0 0 0.2px;
                }
            }
        }
    }

    &__results {
        position: relative;
        margin: 30px 0 0 0;
        min-height: 56px;
        
        > p:nth-child(2) {
            display: inline;
            margin-left: 18px;
            color: #3b454e;
            font-size: 15px;

            span {
                margin: 0 5px;
                color: #3b454e;
                font-size: 15px;
                text-shadow: 0 0 0.2px;
                font-weight: 500;

                @media only screen and (max-width: $md-devices) {
                    margin: 0;
                }
            }
        }

        h2 {
            display: inline;
            margin: 20px 0 10px 0;
            text-transform: capitalize;
            font-size: 17px;

            span {
                text-transform: capitalize;
                color: #3b454e;
                font-size: 15px;
                margin-left: 20px;
            }

            @media only screen and (max-width: $md-devices) {
                display: block;
                margin-top: 10px;
            }
        }

        > div {
            &.checkAll {
                padding: 15px 0 0 9px;

                div.error__container {
                    display: table;

                    p {
                        margin-bottom: 7px;
                        border-bottom: 2px solid $error-color;
                    }
                }
            }

            label {
                font-size: 14px;
                margin-left: 8px;
            }
        }

        @media only screen and (max-width: $md-plus-devices) {
            padding-top: 35px;

            > p:nth-child(2) {
                display: block; 
                margin-left: 0;
            }
        }
    }

    &__table {
        margin: 15px 0 20px 0;
        position: relative;

        table {
            width: 100%;
            overflow-x: auto;
            min-height: 435px;
            height: calc(100vh - 490px);
            display: block;
            border-bottom: 1px solid #e7e7e7;

            &.emptyTable {
                height: calc(100vh - 314px);
                border-bottom: none;
            }

            &.expandVH {
                height: 38vh;
            }

            &.singleEdit {
                height: calc(100vh - 400px);
            }

            > thead {
                position: sticky;
                top: 0;
                z-index: 1;

                @media only screen and (min-width: 2000px) {
                    display: inline-table;
                    width: 100%;
                }

                th {
                    padding: 12px 6px;
                }
            }

            > tbody {
                @media only screen and (min-width: 2000px) {
                    display: inline-table;
                    width: 100%;
                }

                > tr {
                    td:nth-child(1) {
                        min-width: 37px;
                        width: 2%;
                    }

                    td:nth-child(2) {
                        min-width: 72px;
                        width: 6%;
                    }

                    td:nth-child(3) {
                        width: 30%;
                    }

                    td:nth-child(4) {
                        width: 5%;
                    }

                    td:nth-child(5) {
                        width: 10%;
                    }

                    td:nth-child(6) {
                        width: 6%;
                    }

                    td:nth-child(7) {
                        width: 6%;
                    }

                    td:nth-child(8) {
                        width: 6%;
                    }

                    td:nth-child(9) {
                        width: 12%;
                    }

                    td:nth-child(10) {
                        width: 7%;
                    }

                    td:nth-child(11) {
                        min-width: 190px;
                        width: 10%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $lg-devices) {
        &__menu {
            &--buttons {
                text-align: right;

                > :nth-child(0) {
                    padding: 8px 12px;
                    font-size: 13px;
                    font-weight: 400;
                    margin-left: 20px;
                }

                p, div {
                    text-align: left;
                }

                &.mass-order {
                    overflow: auto;
                    max-height: 260px;
                    padding-right: 12px;
                }
            }
        }
    }

    @media only screen and (max-width: $md-plus-devices) {
        &__intro {
            p {
                max-width: unset;
                width: 100%;
            }

            > button {
                position: unset;
                display: inline-block;
                margin-top: 30px;
            }

            &.show {
                .overview__intro__wrapper {
                    justify-content: center;
                    align-items: flex-start;
                    flex-wrap: nowrap;
                    flex-direction: column;
                    
                    div {
                        width: 100%;
                    }
                }
            }
        }

        &__table {
            overflow-x: auto;

            table {
                overflow-x: unset;
            }

            div {
                text-align: left;
            }
        }

        &__menu {
            padding: 20px 20px 0 20px;

            &--buttons {
                div.topped {
                    margin-top: 30px;
                }

                div > button {
                    margin: 0 75px 14px 0 !important;

                    &:nth-child(4) {
                        display: block;
                    }
                }
            }

            &--content {
                text-align: left;

                div {
                    margin-top: 0;
                    padding: 12px 8px 10px 8px;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-devices) {
        &__menu {
            &--buttons {
                text-align: center;

                div button {
                    margin: 0 40px 14px 0 !important;
                }
            }

            &--content {
                div {
                    label {
                        display: block;
                        margin-bottom: 5px;
                    }

                    button {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

.loadingOverlay {
    position: absolute;
    background-color: #cacaca99;
    z-index: 3;
    display: block;
    width: 100%;
    width: -moz-available;          
    width: -webkit-stretch;  
    width: stretch;
    height: 100%;
    height: -moz-available; 
    height: -webkit-stretch;
    height: stretch;

    tr {
        text-align: center;
        @include absolute-center;
        margin: 0;
        min-width: 100%;

        &:hover {
            background-color: unset!important;
        }

        td {
            padding: 0!important;
            font-size: 15px;
            min-width: 100%;

            span {
                font-weight: 500;
                font-style: italic;
                text-shadow: 0 0 0.2px;
            }
        }
    }
}