.tabs {
    overflow: hidden;
    transition: 0.3s;
    position: relative;

    .templates__form__container__list {
        background-color: #e6e6e6;
        margin: 0;

        &--item {
            background: $white;

            span {
                float: right;

                img {
                    height: 22px;
                }
            }

            > p:nth-child(1) {
                display: inline;
                font-size: 15px;
            }

            &.selected {
                background-color: #f7f8ab;
            }
        }

        > .create__step__body {
            margin-left: 0;

            > div p {
                margin-left: 80px;
                
                @media only screen and (max-width: $md-devices) {
                    margin-left: 0;
                }
            }

            &:nth-child(3) {
                align-items: center;
            }
        }
    }

    &--indent {
        margin-left: 46px;
        box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.25);

        @media only screen and (max-width: $md-plus-devices) {
            margin-left: 0;
            padding: 0 5px;
        }

        @media only screen and (max-width: $sm-devices) {
            padding: 0;
        }
    }

    &__nav {
        min-height: 30px;
        display: flex;
        border-radius: 3px 3px 0 0;

        &__link {
            background-color: $grey;
            padding: 10px 10px 12px 10px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            font-size: 15px;
            color: $blue-grey;
            font-weight: 500;
            width: 33.33%;
            text-shadow: 0 0 0.3px;

            &:hover {
                text-shadow: 0 0 0.3px;
                text-decoration: underline;
            }

            &--active {
                background-color: #e6e6e6;
                color: $blue-grey;
                text-shadow: 0 0 0.3px;
                border-radius: 4px 4px 0 0;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__body {
        padding: 18px 12px 18px 12px;
        background-color: #e6e6e6;
        border-radius: 0 0 3px 3px;
        box-shadow: $box-shadow;

        &__content {
            position: relative; 

            &.pager {
                .templates__form__container__list {
                    margin-bottom: 35px;
                }
            }
            
            .pagination > .pagination__list {
                margin: 15px 0 0 0;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        &__footer {
            padding: 16px 5px 0 52px;
            display: flex;
            justify-content: space-between;
            
            p {
                margin: 0;
                font-size: 14px;
                
                &:not(.tabs__body__footer--link, .tabs__body__footer--link > a) {
                    text-align: right;
                    font-style: italic;
                    font-weight: 400;
                }
            }
            
            &--link {
                text-align: left;
                
                a {
                    font-weight: 500;
                    text-decoration: underline;
                    cursor: pointer;
                    color: rgb(30, 88, 248);
                }
            }

            @media only screen and (max-width: $md-plus-devices) {
                padding-left: 0;
            }

            @media only screen and (max-width: $xs-devices) {
                display: block;

                p {
                    
                    &:not(.tabs__body__footer--link, .tabs__body__footer--link > a) {
                        text-align: left;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
