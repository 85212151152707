.booking {
    width: 100%;
    position: relative;

    @media only screen and (max-width: $md-devices) {
        text-align: center;
    }

    h2 {
        margin-top: 8px;
    }

    &__section {
        text-align: left;

        @media only screen and (max-width: $md-devices) {
            text-align: center;
        }

        &--topped {
            padding: 20px 0;
        }

        h2 {
            display: inline-block;
            font-size: 18px;
            margin: 20px 10px 4px 0;
            text-shadow: 0 0 0.2px;
            font-weight: 400;
        }

        &--table {
            margin: 10px 0;
            overflow-x: auto;
            position: relative;

            h3 {
                font-size: 16px; 
                font-weight: 500;

                @media only screen and (max-width: $md-devices) {
                    &.export__heading {
                        margin-top: 40px;
                    }   
                }
            }

            span:nth-child(2) {
                color: $blue-grey;
                font-size: 15px;
                font-weight: 500;

                span {
                    text-shadow: 0 0 0.2px;
                }
            }
        }
    }

    &__list {
        @include clean-lists;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        width: 100%;
        
        @media only screen and (max-width: $md-devices) {
            justify-content: center;
        }

        &--header {
            align-items: baseline;
            width: 100%;
            >p {
                margin-right: 8px;
                font-family: $text-font;
                font-size: 14px;
                margin-bottom: 0;
                display: inline-block;

                &:nth-child(1) {
                    font-weight: 500;
                    text-shadow: 0 0 0.2px;
                    font-size: 16px;
                    margin-right: 15px;
                }
            }
        }

        &--lg {
            grid-template-columns: auto auto auto auto auto auto;
            gap: 10px;
            margin: 5px 0 30px 0;

            p {
                margin-bottom: 0;
                font-size: 13px;

                &.timeout {
                    text-shadow: 0 0 0.2px;
                    font-weight: 500;
                    color: $error-color;
                }
            }
        }

        &--md {
            grid-template-columns: auto auto auto auto auto auto auto auto;
            gap: 15px;
            margin: 15px 0 30px 0;
        }

        &__item {
            text-align: center; 
            border-radius: 6px;
            border: 1px solid $light-blue-grey;
            box-shadow: $box-shadow-light;
            max-width: 80px;
            width: 100%;
            cursor: pointer;
            background-color: $blue-grey;
            color: white;
            padding: 10px 8px;
            min-height: 46px;

            &.disabled {
                background-color: #d4d4d4;
                cursor: not-allowed;

                p, span, &:hover p, span {
                    color: #8d8d8d!important;
                }
            } 

            &--lg {
                min-height: 78px;
            }

            &.error {
                border: 1px solid $warning-color;
            }

            &.forbidden:hover {
                cursor: not-allowed;
            }

            &:hover:not(.disabled):not(.not-available) {
                background-color: $white;
                color: $blue-grey;
                display: flex;
                justify-content: center;

                p, span {
                    display: none;
                }

                p:last-child {
                    text-shadow: 0 0 0.2px;
                    color: $blue-grey;
                    font-size: 18px;
                    margin: 0;
                    display: inline-block;
                    align-self: center;
                    font-family: $heading-font;
                    text-transform: capitalize;
                }
            }

            &.item-added {
                background-color: $transparent-yellow;
                border: 1px solid $light-yellow;
                box-shadow: $box-shadow-card;
                cursor: pointer;

                p, span {
                    color: $blue-grey;
                    text-shadow: 0 0 0.2px;
                }

                span:nth-child(2) {
                    display: none;
                }

                span:nth-child(3) {
                    display: block;
                    margin-top: 10px;
                    font-weight: 500;
                    text-shadow: 0 0 0.2px;
                }

                &:hover {
                    background-color: $grey;
                    border: 1px solid $light-blue-grey;

                    p, span {
                        color: $error-color;
                    }

                    span {
                        display: none;
                    }

                    p:last-child {
                        color: $error-color;
                        display: block;
                        font-size: 18px;
                        margin-top: 0;
                    }
                }
            }

            &.selecting {
                background-color: $white;

                p {
                    color: $blue-grey;
                    text-shadow: 0 0 0.2px;
                }
            }

            &.not-available {
                cursor: not-allowed;
                animation-duration: 6s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-name: loadingLinear;
                background: linear-gradient(to right, #3b454e 8%, #4d5a66 18%, #3b454e 43%);

                p {
                    color: rgb(177, 177, 177);
                }
            }

            p {
                font-weight: 500;
                padding-top: 3px;
                font-size: 15px;
                color: white;

                &:last-child {
                    display: none;
                }
            }

            span {
                font-size: 13px;
                margin-top: 15px;
                color: white;;
            }
        }

        &__form {
            padding: 16px 18px 20px 18px;
            border: 1px solid #f0f0f0;
            box-shadow: $box-shadow-light;
            width: 100%;
            text-align: center;
            border-radius: 3px;
            text-align: left;
            background-color: #fcfcfc;
            position: relative;
            max-width: 100%;

            &--success {
                background-color: rgba(217, 245, 199, 0.493);
                border: 1px solid rgb(208, 231, 193);
                box-shadow: $box-shadow-light;
            }

            h3 {
                font-weight: 500;
                font-size: 26px;
                text-shadow: 0 0 0.2px;
                margin: 0;
            }

            h4 {
                text-align: right;
                font-size: 15px;
                font-weight: 500;
                color: $error-color;
                margin: 5px 10px 0 0;
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;

                &:hover {
                    font-weight: 500;
                    text-decoration: underline;
                }
            }

            .form__container {
                display: inline-flex;
                width: 100%;
                align-items: flex-start;
                padding-top: 5px;
                justify-content: space-between;
                
                >.form__item {
                    width: 24%;
                    margin-top: 0;

                    &--sm {
                        width: 10%;
                    }
                    
                    label {
                        font-size: 14px;
                        font-weight: 500
                    }
                }

                &--comment {
                    display: block;

                    >.form__item {
                        width: 100%;
                    }

                    textarea {
                        border-radius: 3px;
                        outline: none;
                        border: 1px solid rgba(118, 118, 118, 0.3);
                        height: 60px;
                        display: block;
                        width: 100%;
                        padding: 8px 10px;
                        font-family: "Quicksand", sans-serif;

                        &::placeholder {
                            font-style: italic;
                            font-size: 14px;
                            font-weight: 400;
                            opacity: 0.8;
                        }
                    }
                }

                &--checkbox {
                    padding: 10px 0;
                    display: inline-flex;
                    width: 100%;
                    align-items: flex-start;
                    justify-content: space-between;

                    >.form__item {

                        &--sm {
                            width: 100%;
                            display: inline-block;

                            &:first-child {
                                width: 61%;
                            }
                          
                        }

                        label { 
                            margin-left: 10px;
                        }
                    }
                }
            }

            @media only screen and (max-width: $lg-devices) {
                display: flex;
                padding: 14px 15px 16px 15px;
                align-items: flex-start;
                flex-wrap: wrap;

                h3 {
                    margin: 0 0 8px 0;
                    text-align: left;
                    order: -1;
                }

                h4 {
                    align-self: flex-start;
                    margin: 0;
                    order: -1;
                }

                .form__container:not(.form__container--comment, .form__container--checkbox) {
                    >.form__item {
                        width: 28%;

                        &--sm {
                            width: 10%;
                        }
                    }
                }
                .form__container--checkbox {
                    display: block;
                    width: 100%;

                    >.form__item {
                        &--sm {
                            width: 100%;

                            &:first-child {
                                width: 100%;
                            }
                        }

                        label { 
                            margin-left: 10px;
                        }
                    }
                }
            }

            @media only screen and (max-width: $md-plus-devices) {                
                width: 100%;
                text-align: center;

                h3 {
                    margin: 0 0 4px 0;
                }

                .form__container:not(.form__container--comment) {
                    display: block;
                    
                    >.form__item {
                        width: 100%;

                        &__label, &__label--lg {
                            margin: 14px 0 0 0;
                        }

                        &__step, &__step--lg {
                            padding: 3px 0 0 0;
                        }
                    }
                }
            }
        }

        &__card {
            @extend .booking__list__form;

            h3 {
                margin: 10px 0;
            }
        }

        &--sm {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-top: 15px;

            .booking__list__form {
                width: 80%;
                display: inline-block;
                padding: 25px 30px 20px 30px;

                .form {
                    text-align: center;

                    h3 {
                        margin-left: 0;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}