.form {
    display: grid;
    text-align: left;
    position: relative;

    &__item {
        display: block;
        margin-top: 24px;
        text-align: left;

        &__label {
            display: inline-block;
            width: 20%;
            margin-top: 6px;
            font-size: 14px;

            &--lg {
                width: 100%;
                display: block;
            }

            &--required {
                font-weight: 600;
                font-size: 13px;
                color: $form-error;

                &:hover {
                    text-shadow: 0 0 0.2px;
                    cursor: help;
                }
            }
        }

        &__step {
            width: 76%;
            float: right;
            text-align: left;
            padding-left: 10px;

            & > .invalid {
                border: 1px solid rgba(190, 18, 18, 0.781);
            }

            &--lg {
                width: 100%;
                display: block;
                padding: 10px 0 0 0;
                float: unset;
            }

            &--input {
                border-radius: 3px;
                outline: none;
                border: 1px solid rgba(118, 118, 118, 0.3);
                height: 32px;
                display: block;
                width: 100%;
                padding: 2px 5px;
                font-family: $text-font;

                &::placeholder {
                    font-weight: 400;
                    opacity: 0.7;
                    padding-left: 2px;
                }

                &.sm {
                    width: 70%;
                    float: right;
                }
            }

            &--select {
                @extend .form__item__step--input;
            }

            &--error {
                display: block;
                font-size: 13px!important;
                text-transform: none!important;
                margin-top: 6px;
                font-weight: 500;
                color: $form-error!important;
            }
        }

        &__help {
            width: 22px;
            margin-top: 5px;
            cursor: help;
        }
    }

    &__tip {
        font-size: 14px;
        margin-left: 2px;
        font-style: italic;
    }

    &__inline {
        display: flex;
        align-items: flex-start;

        td {
            align-items: flex-start;
        }
    }
}

.create {
    max-width: 980px;
    padding-right: 20px;

    &__step {
        margin-bottom: 30px;
        display: grid;
        width: 100%;

        &__header {
            margin-bottom: 16px;

            span {
                font-family: $heading-font;
                background-color: $blue-grey;
                color: white;
                font-weight: 500;
                border-radius: 50%;
                font-size: 18px;
                box-shadow: $box-shadow-light;
                display: inline-block;
                background-color: $yellow;
                color: $blue-grey;
                padding: 4px 10px;
                width: 30px;
                text-align: center;
            }

            h2 {
                display: inline-block;
                margin-left: 15px;
                font-size: 20px;
                font-weight: 500;
            }

            @media only screen and (max-width: $sm-devices) {
                text-align: center;

                h2 {
                    margin-left: 0;
                }

                span {
                    font-size: 24px;
                    padding: 10px 18px;
                    min-width: 55px;
                }
            }
        }

        &__body {
            margin-left: 46px;
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            max-width: 900px;

           > div:not(.create__step__body--radio) {
                width: 50%;

                &:nth-child(2) {
                    padding-left: 14px;
                }

                label {
                    display: inline-block;
                    margin: 0 30px 8px 0;
                    min-width: 50px;
                } 

                input {
                    border-radius: 3px;
                    outline: none;
                    border: 1px solid rgba(118, 118, 118, 0.3);
                    height: 32px;
                    display: inline-block;
                    width: 210px;
                    padding: 2px 5px;
                    cursor: pointer;

                    &::placeholder {
                        margin-top: 7px;
                        font-size: 13px;
                        font-style: italic;
                        font-weight: 400;
                    }

                    &:disabled {
                        cursor: not-allowed;
                    }

                    &.checkmark {
                        width: auto;
                        display: inline-block;
                        vertical-align: middle;
                        width: 16px;
                        height: 28px;
                        padding-bottom: 10px;
                    }

                    @media only screen and (max-width: 1600px) {
                        width: 180px;
                    }
                }

                p {
                    display: block;
                    width: 100%;
                    font-style: italic;
                    color: $form-error;
                    text-align: left;
                    margin: 8px 2px 0 0;
                    font-size: 14px;
                }
            }

            &--option {
                padding: 0 0 0 53px;
                display: inline-flex;
                
                button {
                    font-size: 16px;
                    color: $blue-grey;
                    font-weight: 400!important;
                    text-decoration: underline;
                    margin-bottom: 10px;

                    &:hover {
                        font-weight: 500!important;
                    }
                }

                @media only screen and (max-width: $md-plus-devices) {
                    padding-left: 4px;
                }
            }

            &--radio {
                width: 95%;

                label.container {
                    margin-right: 50px;
                    font-size: 15px;
                }

                div {
                    padding-top: 30px;

                    p, input {
                        display: inline;
                        font-size: 14px;
                        line-height: 1.8;
                    }
                    
                    input[type="number"] {
                        width: 30px;
                        height: 28px;
                        text-align: center;
                        margin: 0 6px 0 6px;
                        -moz-appearance: textfield;

                        &:hover, &:focus {
                            -moz-appearance: number-input;
                        }
                    }

                    .form--error {
                        display: block;
                        font-style: italic;
                        margin: 8px 2px 0 0;
                        font-size: 14px;
                        color: $form-error;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                }
            }

            @media only screen and (max-width: $md-plus-devices) {
                margin-left: 0;
                padding: 0 5px;
            }

            @media only screen and (max-width: $md-devices) {
                flex-wrap: wrap;
                max-width: unset;

                > div:not(.create__step__body--radio) {
                    width: 100%;
                    text-align: left;

                    &:nth-child(2) {
                        padding: 20px 0 0 0;
                    }

                    input:not(#publishData):not(.sm-number-input) {
                        width: 100%;
                    }
                }

                &--radio {
                    width: 100%;

                    div {
                        p, input {
                            line-height: 2.3;
                        }
                    }
                }
            }

            @media only screen and (max-width: $sm-devices) {
                margin-left: 0;
                display: inline-block;
            }
        }

        img {
            height: 30px;
            margin-right: 10px;
        }

        &__indent {
            padding-left: 54px;

            @media only screen and (max-width: $md-plus-devices) {
                padding: 0;
            }
        }

        @media only screen and (max-width: $sm-devices) {
            margin-bottom: 60px;

            &:nth-child(4) {
                margin-bottom: 20px;
            }
        }
    }

    @media only screen and (min-width: 2000px) {
        max-width: 1200px
    }

    @media only screen and (max-width: 1450px) {
        max-width: unset;
    }

    @media only screen and (max-width: $md-plus-devices) {
        padding-right: 0;
    }

    @media only screen and (max-width: $sm-devices) {
        text-align: center;
        padding-top: 30px;

        h2 {
            margin-left: 0;
            display: block;
        }
    }

    .collection {
        display: flex;
        padding: 10px 0 0 0;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;

        &__item {
            padding: 10px 0;
            margin: 5px 10px 5px 0;
            background-color: $blue-grey;
            box-shadow: $box-shadow-light;
            text-align: center;
            cursor: pointer;
            min-width: 150px;
            border-radius: 5px;
            margin-bottom: 10px;
            width: fit-content;
            position: relative;
            min-height: 80px;

            p {
                text-align: center;
                color: $white;
                font-style: normal;
                font-size: 13px;
                margin: 3px 0 0 0;
                letter-spacing: 1px;
            }

            p:nth-child(1) {
                font-weight: 500;
                font-family: $heading-font;
                font-size: 14px;
                margin: 0 0 8px 0;
                text-transform: capitalize;
                letter-spacing: unset;
            }

            &--feedback {
                @include absolute-center;
                color: $yellow!important;
                text-transform: uppercase!important;
            }

            &:hover {
                box-shadow: $box-shadow-card;

                p {
                    color: $yellow;
                }
            }
        }
    }
}

.templates {
    .table__head {
        background-color: $light-yellow;
        color: $blue-grey;
        font-size: 14px;
        text-shadow: 0 0 0.2px;
    }

    tbody tr > td {
        padding: 10px 10px;
        font-weight: 400;
        font-size: 14px!important;
        word-break: break-word;

        > span {
            &:not(:last-child):after {
                content: ",";
                margin-right: 6px;
            }
        }

        button {
            font-size: 14px;
            margin-bottom: 0;
            text-decoration: underline;
        }
    }

    &__table {
        overflow-x: auto;
    }

    &__list {
        &--feedback {
            font-weight: 500;
            margin: 0 0 0 5px;
        }
    }

    &__form {
        padding: 0 5px;
        position: relative;
        min-height: 280px;

        div {
            > label {
                min-width: 150px;
                display: inline-block;
                margin-top: 14px;
                text-align: left;

                &.label__long {
                    min-width: 180px;
                }
            }

            input {
                margin: 0;
                min-width: 335px;

                @media only screen and (max-width: 1450px) { 
                    display: inline-block;
                    width: calc(100% - 150px);
                    min-width: 0;

                    &.label__long {
                        width: calc(100% - 180px);
                    }
                }

                &.input--error {
                    border-color: $error-color;
                    background-color: #fedce542;
                }
            }

            p.error-text {
                margin: 3px 0 8px 150px;
                text-align: left;
                font-size: 14px;

                &.label__long {
                    margin: 3px 0 8px 180px;
                }
            }
        }

        &__container {
            margin-top: 5px;

            &--inline {
                display: inline-block;

                p {
                    margin: 0;
                    font-size: 14px;
                }
            }

            &__list {
                display: inline-flex;
                align-items: stretch;
                flex-wrap: wrap;
                width: 100%;
                margin-top: 15px;
                gap: 18px;
    
                &--item {
                    background: rgba(250, 250, 250, 0.493);
                    border: 1px solid rgb(245, 245, 245);
                    border-left: 3px solid $yellow;
                    border-radius: 4px;
                    padding: 8px 5px 4px 8px;
                    text-align: left;
                    cursor: pointer;
                    width: calc(100% / 3 - 12px);
                    transition: 0.2s;
                    box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.25);
 
                    &:hover {
                        background-color: $light-yellow;
                        border-left: 6px solid $yellow;
                        box-shadow: $box-shadow-card;

                        p {
                            font-weight: 500;
                        }
                    }
                    
                    &.selected {
                        background-color: $transparent-yellow;
                    }
                    
                    > p {
                        word-break: break-word;
                        font-family: $heading-font;
                        font-size: 14px;
                        margin: 0 0 4px 0;

                        &:nth-child(2), &:nth-child(3)  {
                            text-align: left;
                            text-transform: lowercase;
                            font-size: 13px;
                            font-weight: 400;
                            display: inline-block;
                        }
                        
                        &:nth-child(2) {
                            margin-right: 10px;
                        }
                    }

                    > div {
                        > p {
                            display: inline-block;
                            margin-right: 5px;
                            margin: 2px 5px 2px 0;
                            font-size: 13px;

                            &:nth-child(1) {
                                font-weight: 500;
                                margin-right: 7px;
                            }

                            &:nth-last-child(1) {
                                margin-right: 0;
                            }
                        }
                    }

                    @media only screen and (max-width: $md-devices) {
                        width: 48%;
                    }

                    @media only screen and (max-width: $sm-devices) {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1300px) {
        &__form {
            min-height: unset;

            &__container {
                text-align: left;
    
                &--inline {
                    display: inline-block;
    
                    p {
                        margin: 0;
                        font-size: 14px;
                    }
                }
            }

            div {
                > label {
                    text-align: left;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-devices) {
        &__form {
            div {
                > label {
                    display: block;
                    margin: 14px 0 3px 0;
                }

                input, input.label__long  {
                    width: 100%;
                    min-width: unset;
                }

                > p.error-text {
                    margin: 3px 0 10px 0;
                    text-align: left;
                }
            }

            &__notice {
                width: 100% !important;
                margin: 40px 0 50px 0 !important;
            }
        }
    }
}

.list {
    margin-top: 40px;

    h3 {
        margin: 0 0 5px 0;
        font-size: 17px!important;

        span {
            font-size: 14px;
            margin-left: 10px;
            font-weight: 400;
        }
    }

    &__visibility {
        height: 24px;
        vertical-align: middle;

        &--lg {
            height: 18px;
            vertical-align: bottom;
        }
    }

    &__content {
        padding: 25px 0 5px 0; 

        p:nth-child(1) {
            margin: 0 0 0 5px;
        }

        > .booking__section--table {
            text-align: left;
            background-color: $grey;
            border-radius: 2px;
            box-shadow: $box-shadow-light;
            padding: 22px 14px 12px 14px; 
            margin: 30px 0 10px 0;
        }

        &.limited {
            margin-top: 0;
            padding-top: 15px;
            
            > .booking__section--table {
                margin-top: 0;

                > .export__button {
                    top: 14px;
                    right: 14px;
                }
            }
        }
    }

    &__form {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            margin-top: 20px;
            background-color: $blue-grey;
            border-radius: 3px;
            padding: 10px 12px 7px 8px;
            color: white;

            > h2 {
                margin: 0;
                font-size: 16px;
                text-transform: uppercase;
                color: white;
                height: 28px;
                line-height: 1.9;    

                > span {
                    color: white;
                    font-size: 14px;
                }
            }

            span {
                text-transform: lowercase;
                font-size: 15px;
                font-weight: 400;
                margin-left: 10px;
            }

            &--group {
                display: flex;
            }

            &--year {
                select  {
                    min-width: 90px;
                    height: 32px;
                    margin-right: 12px;
                    border-radius: 3px;
                    color: #6a7075;
                    border: none;
                    cursor: pointer;
                    padding: 2px 5px;
                }
            }

            &--search {
                text-align: right;

                input, select {
                    min-width: 160px;
                    height: 32px;
                    margin-right: 12px;
                    border-radius: 3px;
                    color: #6a7075;
                    border: none;
                    cursor: pointer;

                    &::placeholder {
                        color: #6a7075;
                    }
                }
                
                input[type="checkbox"] {
                    min-width: 18px;
                    height: 13px;
                    margin: 0 8px 0 0;
                }

                .searchbar {
                    display: inline-block;
                    width: fit-content;
                    position: relative;

                    img {
                        height: 19px;
                        position: absolute;
                        left: 6px;
                        top: 6px;
                        opacity: 0.9;
                    }

                    input {
                        position: relative;
                        padding-left: 32px;
                    }

                    input:disabled + img {
                        opacity: 0.6;
                        cursor: not-allowed;
                    }
                }

                input:hover:not(:disabled), select:hover:not(:disabled), .searchbar:hover:not(:disabled){
                    color: #565b5f;
                    text-shadow: 0 0 0.2px;
                }

                .reset--search {
                    color: $grey;
                }

                > section {
                    .list__button {
                        margin-right: 15px;
                    }

                    .inline-checkbox {
                        accent-color: $yellow;
                    }
                }

                > section > button, > button {
                    color: $grey;
                    text-decoration: none;

                    &.emptyFilter::after {
                        content: " Nullstill filter";
                        font-size: 13px; 
                    }
                    
                    &.emptySearch::after {
                        content: " Tøm søk";
                    }

                    &.active, &:hover {
                        color: $yellow;
                    }
                }
            }

            input#historicDate {
                height: 35px;
                margin-right: 20px;
            }

            #customerFilter {
                position: relative;
                display: inline-block;

                .searchbar {
                    position: relative;
                    
                    input {
                        height: 35px;
                        margin: 0;
                        padding-left: 32px;
                    }

                    img {
                        height: 19px;
                        position: absolute;
                        left: 7px;
                        top: 7px;
                        opacity: 1;
                        cursor: pointer;
                    }

                    input:disabled + img {
                        opacity: 0.6;
                        cursor: not-allowed;
                    }
                }
            }

            @media only screen and (max-width: 1300px) {
                &--search {
                    input:not([type="checkbox"]), select {
                        margin: 5px 5px 10px 5px;
                        width: 160px;
                        min-width: unset;
                    }
                }

                .searchbar {
                    img {
                        left: 10px;
                        top: 11px;
                    }
                }
            }

            @media only screen and (max-width: $lg-devices) {
                flex-direction: column;
                padding: 15px 0;

                h2 {
                    margin-bottom: 14px;
                }

                &--search {
                    > section {
                        .list__button {
                            margin-right: 10px;
                        }
                    }
                    
                    button.text__btn::after {
                        content: " ";
                        font-size: 13px;
                    }
                }
            }

            @media only screen and (max-width: $md-devices) {
                flex-wrap: wrap;
                align-content: center;

                &--search {
                    text-align: center;

                    input,
                    select {
                        margin: 5px 5px 10px 5px;
                        width: 80%;
                        width: 150px;
                        min-width: unset;
                    }

                    > input {
                        margin: 0 8px 0 22px;
                    }

                    input[type="checkbox"] {
                        min-width: 18px;
                        height: 13px;
                        margin: 0 8px 0 0;
                        width: unset;
                    }
                }
            }
        }

        &__table {
            overflow-x: auto;

            p:nth-child(1){
                margin: 25px 0 25px 5px;
                
                span {
                    margin: 0 10px;
                    font-weight: 600;
                }
            }

            > table > tbody > tr > td > span {
                text-shadow: 0 0 0.2px;
            }

            > table tbody tr td img {
                cursor: pointer;
                height: 26px;
            }
        }

        button.confirm {
            margin: 2px 0 10px 0;
        }
        
        button.plain {
            padding: 7px 14px 6px 12px;
            color: $blue-grey;
            margin: 0 10px 2px 0;
            border-radius: 5px;
            text-transform: unset;
            font-size: 14px;
            font-family: $text-font;
            color: #6a7075;
            border: 1px solid transparent;
            
            &:not(:disabled):not(.print):not(.attendance) {
                background-color: $yellow;
                box-shadow: $box-shadow;
                color: $blue-grey;
                text-shadow: 0 0 0.2px;
            }

            &.print {
                margin: 0 0 0 20px;
                border-radius: 3px;

                span {
                    margin-left: 0;
                }
            }

            img {
                height: 17px;
                vertical-align: text-top;
                margin-right: 6px;
            }

            &:hover:not(:disabled) {
                background-color: #ffcf49;
            }
        }
    }
}

.modal__form {
    &__header {
        background-color: $yellow;
        padding: 12px 25px;

        &--centered {
            text-align: center;
        }

        h1 {
            margin: 0;
            font-size: 20px;
        }
    }

    &__choice {
        padding: 20px 26px 0 26px;
        text-align: center;

        button.text__btn  {
            font-size: 16px;

            &.active {
                border-radius: 0;
                border-bottom: 3px solid $yellow!important;
            }

            &:hover:not(.active) {
                text-shadow: 0 0 0.2px;
                font-style: italic;
            }
        }
    }
    
    &__content {
        padding: 5px 26px 20px 26px;
        min-height: 200px;
        position: relative;
        
        h2 {
            font-size: 16px;
            font-weight: 500;
            margin: 16px 0 0 0;

            span {
                margin-left: 6px;
                font-weight: 400;
            }
        }

        &--desc {
            font-size: 14px;
        }

        &--centered {
            text-align: center;
            width: 100%;
        }
    }

    &__item {
        width: 100%;
        margin: 8px 0 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &--topped {
            margin-top: 30px;
        }

        &--status {
            font-family: $text-font;
            color: $blue-grey;
            font-weight: 500;
            text-transform: uppercase;
        }
        
        label {
            width: 24%;
            font-size: 14px;
            
            span {
                font-size: 14px;
                margin-left: 4px;
                
                @media only screen and (max-width: $lg-devices) {
                    margin-left: 0;
                    font-weight: 500;
                }
            }
        }

        div {
            padding: 0;
            width: 80%;
            background-color: white;

            > .button__clear {
                top: 3px; 
            }
        }

        &--xl {
            label {
                width: 28%;
            }

            div {
                width: 76%;

                @media only screen and (max-width: $lg-devices) {
                    input:not(.modal__form__item--xl--checkbox) {
                        width: 100%;
                    }
                }
            }
        }

        &--double {
            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                
                > div {
                    display: contents;
                    width: 50%;

                    label {
                        display: inline-block;
                        width: 28%;
                        min-width: 186px;

                        &.modal__form__item--double {
                            text-align: right;
                            padding-right: 10px;
                        }
                    }
    
                    > input {
                        width: 100%;
                        max-width: 166px;
                        
                        &.invalid {
                            border: 1px solid rgba(190, 18, 18, 0.781);
                        }
                    }

                    &.modal__form__item--double__container {
                        label {
                            min-width: 100px;
                            text-align: center;
                            font-size: 14px;
                            word-spacing: 8px;
                            letter-spacing: 0.5px;
                            font-weight: 400;
                            text-shadow: 0 0 0.2px;
                        }
                    }
                }

                @media only screen and (max-width: $lg-devices) {
                    display: unset;
                    
                    > div {
                        display: flex;
                        width: 100%;
                        margin-top: 15px;
        
                        label {
                            display: inline-block;
                            width: unset;
                            min-width: 134px;
                            padding-right: 0;
                            text-align: left;

                            &.modal__form__item--double {
                                text-align: left;
                            }
                        }
        
                        > input {
                            width: 100%;
                            max-width: unset;
                        }

                        &.modal__form__item--double__container {
                            label {
                                text-align: unset;
                                min-width: 134px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: $sm-devices) {
        &__item {
            div {
                width: 100%;
                margin-top: 5px;

                > .button__clear {
                    top: 26px; 
                }
            }

            &:not(.item__inline) {
                display: block;
                margin: 0 0 18px 0;

                &:nth-child(2) {
                    margin-top: 30px;
                }
            }

            &--double {
                > div {
                    width: 100%;
                    
                    > div {
                        display: block;
                        label {
                            display: block;
                            margin-bottom: 5px;
                            min-width: unset;
                        }

                        input {
                            min-width: unset;
                        }
                    }
                }
            }
            
        }

        .item__inline {
            label {
                width: 70px;
            }
        }
    }

    &__feedback {
        text-align: center;
        margin-top: 35px;

        &--result {
            > p {
                text-align: center;
                font-size: 14px;
                margin: 0 0 25px 0;
            }
        }
    } 
}

.multiple {
    margin-top: 5px;
    display: inline;

    &--wrap {
        display: flex;
        flex-wrap: wrap;   
        justify-content: space-between;
    }

    &__wrapper {
        display: flex;
        width: 98%;
        flex-direction: row;
        justify-content: space-between;
    }

    &__container {
        display: inline-flex;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1;
        width: 50%;
        
        &__item {
            width: 26%;
            min-width: 150px;
            align-items: flex-start;
            margin-bottom: 8px;

            &:nth-child(3) {
                width: 22%;
                min-width: 140px;
            }
            
            label {
                min-width: 90px;
                font-size: 14px;
                font-family: $heading-font;
                
                span {
                    font-size: 14px;
                    margin-left: 4px;
                    
                    @media only screen and (max-width: $lg-devices) {
                        margin-left: 0;
                    }
                }
            }

            @media only screen and (min-width: $md-plus-devices) and (max-width: 1300px) {
                label {
                    min-width: 90px;
                }

                &:nth-child(4) {
                    label {
                        min-width: 50px;
                    }
                }
            }
    
            div {
                padding: 0;
                margin-top: 3px;

                input[type=text], select, input#reservedBy {
                    width: 100%;
                    height: 28px;
                }

                input[type=checkbox] {
                    padding-top: 10px;
                    vertical-align: sub;
                }

                &.inline {
                    display: inline-block;
                }
            }

            &--single {
                padding-bottom: 10px;
                display: inline-flex;
                width: 100%;
                align-items: center;
                margin-bottom: 0;

                div {
                    display: inline-block;
                }

                label {
                    margin: 3px 0 0 10px;
                }
            }
        }

        &__single {
            width: 50%;
            > .multiple__container__item {
                margin-bottom: 12px;
                width: 40%;
                min-width: 210px;
                max-width: 440px;

                &.datalist {
                    position: relative;

                    .button__clear {
                        background: white;
                    }
                }

                &:nth-child(2) {
                    width: 30%;
                    min-width: 170px;
                }

                &:nth-child(3) {
                    width: 18%;
                    min-width: 40px;
                }
            }
        }
    }

    &__feedback {
        align-items: center;
        > .feedback--center {
            margin: 10px 0 0 12px;
            display: inline-block;
            width: fit-content;
        }
    }

    @media only screen and (max-width: $lg-devices) {
        width: 100%;

        &__wrapper {
            width: 100%;
        }

        &__container {
            display: block;

            &__item:not(.multiple__container__item--single) { 
                width: 95%!important;
            }
        }
    }

    @media only screen and (max-width: $md-plus-devices) {
        padding: 10px 5px 0 5px;

        &__feedback {
            padding-top: 0!important;
        }
    }

    @media only screen and (max-width: $sm-devices) {
        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__container {
            width: 100%;

            &__item { 
                width: 100%!important;
                max-width: 100%!important;

                &.item__inline > div {
                    display: inline-block;
                    width: fit-content;
                    margin: 0 0 0 10px;
                }
            }
        }
    }
}