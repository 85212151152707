.contents {
    height: calc(100% - 100px);
    width: calc(100% - 140px);
    top: 100px;
    left: 140px;
    background-color: #f7f7f7;
    position: fixed;
    padding: 40px;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    position: sticky;
    overflow-x: hidden;

    @media only screen and (min-width: 2000px) {
        padding: 60px 70px;
    }

    &__container {
        position: relative;
        background-color: $white;
        width: 100%;
        height: max-content;
        min-height: 100%;
        padding: 20px;
        box-shadow: $box-shadow-light;
        border-radius: 3px;

        &--help {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
            z-index: 44;

            img {
                height: 20px;
                vertical-align: middle;
                margin: 0 4px 3px 0;
            }

            &:hover {
                text-shadow: 0 0 0.2px;
            }
        }

        > p:nth-child(2) {
            max-width: 950px;
            line-height: 1.4;
        }

        &.tableView {
            min-height: unset;
            margin-bottom: 80px;
            padding-bottom: 0;
        }
        
        &.liftedLayout {
            &--lg {
                margin-bottom: 140px;
                
            }

            @media only screen and (max-width: $lg-devices) {
                margin-bottom: 160px;
                
                &--lg {
                    margin-bottom: 245px;
                }
            }
    
            @media only screen and (max-width: $sm-devices) {
                
                &--lg {
                    margin-bottom: 260px;
                }
            }
        }

        &.expandedTable {
            margin-bottom: 0;
            #overviewTable {
                min-height: 510px;
            }
        }
    }
    
    @media only screen and (max-width: $md-plus-devices) {
        width: 100%;
        height: 100%;
        display: block;
        overflow-x: hidden;
        padding: 35px;
        
        &__container {
            display: inline-block;
            min-height: 700px;
            text-align: center;       
        }
    }  
    
    @media only screen and (max-width: $md-devices) {
        padding: 25px 20px;
    }  

    @media only screen and (max-width: $sm-devices) {    
        text-align: center;
    }

    @media only screen and (max-width: $xs-devices) { 
        padding: 0;

        &__container {
            padding: 40px 20px;      
        }
    }
}
