.dashboard {
    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 8px 10px;
        background-color: $blue-grey;
        border-radius: 2px 2px 0 0;

        h2, h3 {
            color: white;
            word-spacing: 1px;
            letter-spacing: 0.5px;
            margin: 0;
        }

        h2 {
            display: inline-block;
            font-size: 17px;
            margin-top: 5px;
        }

        h3 {
            font-size: 16px;
            font-weight: 500;
        }

        &__dropdown {
            label {
                text-transform: uppercase;
                font-size: 13px;
                color: $yellow;
                padding-right: 5px;
                font-weight: 500;
                letter-spacing: 0.5px;
                word-spacing: 3px;
            }

            @media only screen and (max-width: $xs-devices) { 
                input {
                    margin-top: 6px;
                }
            }
        }

        &--lg {
            padding: 12px 12px 12px 10px; 
        }
    }

    &__week {
        &--wrapper {
            padding: 40px 12px 12px 12px;
        }

        &--summary {
            min-height: 30px;
            margin-bottom: 44px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .week__wrapper {
                width: 48%;
                display: flex;
                justify-content: space-between;
                background-color: $grey;
                box-shadow: $box-shadow-light;
                padding: 18px;
                border-radius:2px;

                .week__summary {
                    display: flex;

                    &__content {
                        width: 100%;
                        display: block;
                        position: relative;
                        padding: 0 26px;
                        text-align: left;

                        h4 {
                            font-weight: 500;
                            font-size: 18px;
                            margin: 15px 0 8px 0;
                        }

                        &--bulletpoints {
                            width: 100%;
                            display: flex;
                            align-items: flex-start;
                            flex-wrap: wrap;

                            p {
                                display: inline-block;
                                width: 50%;
                                margin: 14px 0 4px 0;

                                &.inline--bullet {
                                    width: 100%;
                                }

                                &.thick {
                                    font-weight: 400;
                                    text-shadow: 0 0 0.2px;
                                }

                                span {
                                    font-weight: 500;
                                    text-shadow: 0 0 0.2px;
                                    margin-right: 2px;
                                }
                            }
                        }

                        &--percentages {
                            ul {
                                @include clean-lists;
                                margin-top: 12px;

                                li {
                                    display: inline-block;
                                    margin: 0 20px 5px 0;
                                    font-family: $text-font;
                                    font-size: 14px;
                                    font-weight: 400;
                                    text-transform: lowercase;
                                    border-bottom: 2px solid transparent;

                                    &:nth-child(4) {
                                        margin-right: 0;
                                    }

                                    span:not(.inline__legend) {
                                        font-weight: 500;
                                        text-shadow: 0 0 0.2px;
                                        margin-right: 2px;
                                    }

                                    span.inline__legend {
                                        width: 10px;
                                        height: 8px;
                                        margin-bottom: 1px;
                                        background-color: transparent;
                                        margin-right: 8px;
                                        border-radius: 2px;
                                        display: inline-block;
                                    }

                                    span.Bygg {
                                        background-color: #f29e0d;
                                    }

                                    span.Havre {
                                        background-color: #5da7ae;
                                    }

                                    span.Hvete {
                                        background-color: #1a2634;
                                    }

                                    span.Rug {
                                        background-color: #ca6144;
                                    }
                                }
                            }
                        }
                    }

                    &__icon {
                        background-color: $yellow;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 48px;
                        width: 50px;
                        padding: 10px 14px;
                        border-radius: 100%;
                        box-shadow: $box-shadow-light;

                        img {
                            height: 25px;
                        }
                    }
                }

                .pie {
                    display: inline-block;
                    text-align: center;

                    .recharts-pie {
                        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.048));
                        -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.048));
                        font-family: $heading-font;
                        text-shadow: none;
                        outline: none;
                    }

                    &__chart__tooltip {
                        background-color: white;
                        padding: 8px 10px 8px 10px;
                        text-align: left;
                        border: 1px solid rgba(118, 118, 118, 0.3);
                        border-radius: 3px;
                        box-shadow: $box-shadow;
                        color: $blue-grey;
                        font-family: $text-font;

                        &__content {
                            ul {
                                @include clean-lists;

                                li {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: 2000px) {
                .week__wrapper {
                    padding: 35px 50px 35px 30px;
                    max-width: 1000px;

                    .week__summary {
                        &__icon {
                            height: 54px;
                            width: 60px;
                            padding: 10px 16px;
    
                            img {
                                height: 28px;
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1450px) {
                .week__wrapper {
                    padding: 14px;

                    .week__summary {
                        &__content {
                            padding: 0 20px;
    
                            &--bulletpoints {
                                p {
                                    padding-right: 6px;
                                }
                            }
    
                            &--percentages {
                                ul {
                                    padding-right: 6px;
    
                                    li {
                                        margin-right: 16px;
                                    }
                                }
                            }
                        }
                    }
                }

                @media only screen and (max-width: 1300px) {
                    flex-wrap: wrap;
                    flex-direction: column;
                    gap: 40px;
    
                    .week__wrapper {
                        width: 100%;
                    }
                }   
            }

            @media only screen and (max-width: $md-devices) {
                .week__wrapper {
                    flex-direction: column;

                    .pie {
                        display: none;
                    }
                }
            }

            @media only screen and (max-width: $sm-devices) {
                .week__wrapper {
                    .week__summary {
                        &__icon {
                            display: none;
                        }

                        &__content {
                            padding: 0;
                            
                            &--percentages {
                                ul {
                                    li {
                                        width: 50%;
                                        display: inline-block;
                                        margin-right: 0;
                                        padding-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__day {    
            &--wrapper {
                background-color: $grey;
                padding: 30px;
                text-align: center;
                justify-content: center;

                h4 {
                    text-align: left!important;
                    margin: 0;
                    padding: 20px 0;
                    font-weight: 400;
                    text-shadow: 0 0 0.2px;
                    font-size: 14px;
                }
            }

            p {
                margin: 15px 0 40px 0;
                text-shadow: 0 0 0.2px;
            }

            &--calendar {
                margin-bottom: 40px ;

                .calendar {
                    ul {
                        @include clean-lists;
                        display: flex;
                        gap: 20px;
                        justify-content: center;
                        align-items: stretch;
                        flex-wrap: wrap;
    
                        li {
                            width: 40%;
                            text-align: center;
                            align-self: stretch;
                            cursor: pointer;
                            max-width: 138px;
                            padding: 8px 30px 6px 30px;
                            box-shadow: $box-shadow-light;
                            background-color: $blue-grey;
                            border-radius: 2px;
                            border: 1px solid $blue-grey;
                            
                            span {
                                color: white;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 1.4;
                            }
    
                            &.todaysDate {
                                span:nth-child(2) {
                                    text-transform: capitalize;
                                    min-width: 50px;
                                    display: inline-block;
                                    text-shadow: 0 0 0.2px;
                                }
                            }
    
                            &.pickedDate {
                                border: 1px solid $yellow;
                                text-shadow: 0 0 0.2px;
    
                                span {
                                    color: $yellow;;
                                }
                            }
    
                            &.emptyDate {
                                background-color: #3b454e1c;
                                border: 1px solid #3b454e17;
                                box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.527);
                                    
                                &.pickedDate {
                                    border: 1px solid #3b454e5d;
                                    text-shadow: 0 0 0.2px;
                                }
    
                                span {
                                    color: $blue-grey;
                                }

                                &:hover {
                                    background-color: #3b454e10;
                                }
                            }
    
                            span:nth-child(2) {
                                text-transform: capitalize;
                            }

                            &:hover {
                                span {
                                    text-shadow: 0 0 0.2px;
                                    box-shadow: $box-shadow;
                                }
                            }

                            &:hover:not(.emptyDate) {
                                background-color: #2a333a;
                            }
                        }
                    }
                }
            }

            &--keys {
                .key {
                    &--wrapper {                        
                        display: flex;
                        justify-content: space-between;
                        gap: 22px;
                    }

                    &__item {
                        width: 100%;
                        max-width: 250px;
                        background-color: $white;
                        box-shadow: $box-shadow;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: auto;
                        min-height: 100px;
                        padding: 14px 10px;
                        cursor: context-menu;
        
                        div {
                            text-align: center;
                            
                            h3 {
                                font-size: 14px; 
                                margin: 8px 0;
                            }
            
                            p {
                                margin: 0 0 8px 0;
                                font-size: 36px;
                                font-family: $heading-font;
                                font-weight: 600;
                                display: inline;
                            }
                        
                            span {
                                font-family: $heading-font;
                                font-size: 10px;
                                text-transform: uppercase;
                                margin-left: 6px;
                            }
                        }
                    } 

                    @media only screen and (max-width: $md-plus-devices) {
                        &--wrapper {                        
                            flex-wrap: wrap; 
                            justify-content: unset;
                        }

                        &__item { 
                            width: 30%;
                            grid-template-columns: auto auto auto;
        
                            @media only screen and (max-width: $md-devices) {
                                width: 47%;
                                grid-template-columns: auto auto;
                            }        
                            @media only screen and (max-width: $sm-devices) {
                                width: 100%;
                                max-width: unset;
                                grid-template-columns: auto;
                            }
                        }
                    }
                }
            }

            &--graph {
                .graph {
                    margin-top: 30px;

                    &--wrapper {
                        .diagram {
                            padding: 15px 20px 20px;
                            background-color: $white;
                            text-align: center;
                            display: flex;
                            justify-content: space-between;
                            min-height: 350px;
                            height: 100%;
                            width: 100%;
                            box-shadow: $box-shadow;
                            position: relative;
            
                            &__cover {
                                background-image: linear-gradient(to top, rgba(105, 105, 105, 0.1) 2%, rgba(0, 0, 0, 0) 2%);
                                background-size: 100% 58px;
                                background-position: left top;
                                min-height: calc(350px - 40px);
                                width: calc(100% - 40px);
                                z-index: 1;
                                position: absolute;
                                top: 0;
                            }
            
                            &--container:not(.no-data):not(.diagram--container--empty) {
                                z-index: 2;
            
                                &:hover {
                                    .diagram__info {
                                        visibility: visible;
                                        opacity: 1;
                                    }
            
                                    .diagram__bar__fill {
                                        background-color: $blue-grey;
                                        box-shadow: $box-shadow-light;
                                    }
            
                                    .diagram__bar__inner {
                                        > p {
                                            text-shadow: 0 0 0.2px;
                                            font-weight: 500;
                                        }
                                    }
            
                                    .diagram__bar__label {
                                        > p {
                                            text-shadow: 0 0 0.2px;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
            
                            &__bar  {
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                position: relative;
            
                                &__inner {
                                    height: 100%;
                                    width: 100%;
                                    overflow: hidden;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-end;
                                    transition: all 0.2s ease-out;
            
                                    p {
                                        margin-bottom: 0;
                                        font-size: 14px;
                                        font-family: $text-font;     
                                        text-shadow: 0 0 0.2px; 
                                    }
                                }
            
                                &__fill {
                                    background-color: $yellow;
                                    width: 100%;
                                    transition: all 0.3s ease-out;
                                    position: relative;
                                    min-width: 60px;
                                    margin: 12px 0 6px 0;
                                    border-radius: 2px 2px 0 0;
                                }
            
                                &__label {
                                    p {
                                        text-align: center;
                                        font-size: 15px;
                                        margin: 8px 0;  
                                        font-family: $text-font;     
                                        font-weight: 500;  
                                    }
                                }
                            }
                            
                            .no-data {
                                .diagram__bar  {
                                    padding-right: 0;   
            
                                    &__label {
                                        > p {
                                            border-bottom: 2px solid $yellow;
                                            text-transform: uppercase;
                                            font-weight: 600;
                                            font-size: 15px;
                                            margin-bottom: 6px;
                                        }
                                    }
                                }     
                                
                                p {
                                    font-size: 16px;
                                    font-weight: 500;
                                }
                            }
            
                            &__info {
                                visibility: hidden;
                                width: 140px;
                                background-color: $blue-grey;
                                color: #fff;
                                text-align: center;
                                border-radius: 6px;
                                padding: 6px 10px;
                                position: absolute;
                                z-index: 1;
                                top: 120px;
                                left: 110%;
                                transition: visibility 0s, opacity 0.1s ease-in-out;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    right: 100%;
                                    margin-top: -5px;
                                    border-width: 5px;
                                    border-style: solid;
                                    border-color: transparent $blue-grey  transparent transparent;
                                }

                                &__ul {
                                    @include clean-lists;
            
                                    li {
                                        font-size: 14px;
                                        font-weight: 400; 
                                        font-family: $text-font;

                                        display: block;
                                        color: $white;
                                        text-align: left;

                                        span {
                                            color: $white;
                                            min-width: 16px;
                                            display: inline-block;
                                        }
            
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
            
                            @media only screen and (max-width: $md-plus-devices) {
                                display: none;
                                position: unset;
                                min-height: unset;
                                display: block;
                                text-align: left;
            
                                &__cover {
                                    display: none;
                                    position: unset;
                                    min-height: unset;
                                }
            
                                &--container:not(.no-data) {
                                    display: block;
                                    margin-bottom: 10px;

                                    .diagram__info {
                                        visibility: visible;
                                        opacity: 1;
                                        position: unset;
                                        width: 100%;
                                        background-color: $white;
                                        text-align: left;
                                        padding: 6px 0;

                                        ul {
                                            li {
                                                color: $blue-grey;  
                                                display: inline-block;
                                                text-shadow: 0 0 0.2px;
                                                margin-right: 20px;

                                                span {
                                                    color: $blue-grey; 
                                                    min-width: unset;  
                                                }
                                            }
                                        }
                                        
                                        &::after {
                                            display: none;
                                        }
                                    }
                                }
            
                                &__bar {
                                    display: block;
                                    padding: 2px 0;
            
                                    &__inner {
                                        overflow: visible;
                                        width: unset;
                                        float: right;
            
                                        p {
                                            font-size: 16px;
                                            font-weight: 500;
                                            display: inline-block;
                                            margin-top: 7px;
                                        }
                                    }
            
                                    &__fill {
                                        min-width: 0;
                                        margin: 0;
                                        display: none;
                                    }
            
                                    &__label {
                                        display: inline-block;
                                        p {
                                            text-align: center;
                                            font-size: 15px;
                                            margin: 8px 0;  
                                            font-family: $text-font;     
                                            font-weight: 500;  
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .diagram__backdrop {
                        width: 100%; 
                        background-color: white;
                        padding: 25px 30px 15px 0;
                        font-family: $text-font;
                        font-size: 14px;
                        font-weight: 500;
                        color: $blue-grey;
                        box-shadow: $box-shadow;

                        .recharts-legend-item {
                            margin: 0 35px 5px 0!important;
                            font-size: 13px;
                            text-shadow: 0 0 0.2px;

                            svg {
                                margin: 0 8px 2px 0!important;
                            }
                        }

                        &--sm {
                            .recharts-legend-wrapper {
                                display: none!important;
                            }
                        }
                        
                        .stacked__bar__tooltip {
                            background-color: white;
                            padding: 10px 33px 13px 13px;
                            text-align: left;
                            border: 1px solid rgba(118, 118, 118, 0.3);
                            border-radius: 3px;
                            box-shadow: $box-shadow;
                            color: $blue-grey;

                            &__label {
                                margin: 0 0 3px 0;
                                padding: 0;
                                font-size: 15px;
                                font-weight: 500;;
                                text-shadow: 0 0 0.2px;
                            }
                            
                            &__content {
                                ul {
                                    @include clean-lists;

                                    li {
                                        display: block;
                                        padding-top: 7px;
                                        font-size: 14px;
                                    }
                                }
                            }

                            &__footer {
                                @extend .stacked__bar__tooltip__label;
                                margin: 10px 0 0 0;
                                font-size: 14px;
                                border-bottom: 1px solid grey;
                                width: fit-content;
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: 2000px) {
                &--calendar, &--keys, &--graph {
                    width: 100%;
                    text-align: center;

                    .calendar , .key, .graph {
                        display: inline-block;
                        width: 100%;
                        max-width: 2200px;
                    }
                }
            }

            @media only screen and (max-width: $sm-devices) {
                &--graph {
                    display: none;
                }
            }
        }

        @media only screen and (max-width: $md-plus-devices) {    
            &--wrapper {
                .dashboard__week__day { 
                    &--wrapper {
                        padding: 10px 14px; 

                        p {
                            margin-bottom: 25px;
                        }

                        .dashboard__week__day--calendar {
                            margin-bottom: 20px;

                            .calendar {
                                ul {
                                    gap: 10px;

                                    li {
                                        width: 50%;
                                        max-width: 120px;

                                        span {
                                            display: block;
                                        }

                                        @media only screen and (max-width: 350px) { 
                                            width: 100%;
                                            max-width: unset;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: $md-devices) {    
            &--wrapper {
                padding: 20px 0; 
            }

            &--summary {
                margin-bottom: 24px;
                gap: 20px;
            }
        }
    }
}