.pill {
    padding: 5px 12px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    cursor: context-menu;
    box-shadow: 0px 2px 6px -6px rgba(0, 0, 0, 0.25);
    display: inline-block;

    &__active {
        background-color: #A8D6A8;
        color: #2F4135;
    }

    &__inactive {
        background-color: #D9D9D9;
        color: #7D8793;
    }

    &__error {
        color: #6D0909;
        background-color: #EB9393;
    }

    &__desc {
        font-size: 13px!important;
        margin-left: 5px;
    }

    &__attendance {
        background-color: white;
        border: 1px solid rgba(215, 215, 215, 0.6117647059);
        color: $blue-grey;
        background-color: #A8D6A8;
        color: #2F4135;
        padding: 5px 12px 5px 10px;

        &--error {
            padding: 5px 12px;
            color: #6D0909;
            background-color: #EB9393;
        }
    }
}